import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Button from "components/CustomButtons/Button.js";
import Divider from "@material-ui/core/Divider";

export default function Step1(props) {
  const { next, back, step0 } = props;
  const { textFormatCSR, signature, subject, pubkey } = step0;

  const onSubmit = (event) => {
    event.preventDefault();
    next();
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div>
          <div>
            <Typography>
              Please verify that this content is correct, acceptable and a
              properly describing the recepient. It will appear in the that you
              are about to issue to your trusted partner.
            </Typography>
            <Divider />
            <Typography>
              <b>Certificate recepient</b>: {subject}
            </Typography>
            <Divider />
            {signature.includes("verify OK") ? (
              <Typography>
                <b>Signature verified successfully!</b>
              </Typography>
            ) : (
              <Typography>
                Signature: <b>NOT EXPECTED!!! {signature}</b>
              </Typography>
            )}
            <Divider />
            <pre>{textFormatCSR}</pre>
            <Divider />
            <Typography>
              <b>Certificate recepient pubkey:</b>
            </Typography>
            <pre>{pubkey}</pre>
          </div>
        </div>

        <div>
          <Button onClick={back}>Back</Button>
          <Button type="submit" variant="contained" color="primary">
            {"Confirm the content & proceed"}
          </Button>
        </div>
      </form>
    </div>
  );
}

Step1.propTypes = {
  next: PropTypes.func,
  back: PropTypes.func,
  step0: PropTypes.object,
};
