import React from "react";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CertificateChain from "./components/CertificateChain";
import Revocation from "./components/Revocation";
import Device from "./components/Device";
import User from "./components/User";
import Partner from "./components/Partner";

export default function ActiveCertificate({ ...rest }) {
  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CertificateChain {...rest} />
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Revocation {...rest} />
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Device {...rest} />
          <User {...rest} />
          <Partner {...rest} />
        </GridItem>
      </GridContainer>
    </>
  );
}
