import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import EventEntry from "./EventEntry";
import LogRecorder from "./LogRecorder";
import Fab from "components/CustomButtons/Fab";
import FormHelperText from "@material-ui/core/FormHelperText";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 300,
  },
}));

export default function VerifiableAuditLog() {
  const classes = useStyles();
  const [device, setDevice] = React.useState("");

  const handleChangeDevice = (event) => {
    setDevice(Number(event.target.value) || "");
  };

  return (
    <div>
      <Fab size="sm" variant="extended" color="primary" disabled={true}>
        <AddIcon />
        Add new
      </Fab>
      {/** <p>You have activated the MQTT subscribers for Verifiable Event Logging for the following streams</p>**/}
      <form className={classes.container}>
        <FormHelperText>Select Log Stream to view</FormHelperText>
        <FormControl className={classes.formControl}>
          <InputLabel id="select-device-label">Log source system</InputLabel>
          <Select
            labelId="select-device-label"
            id="select-device-dialog"
            value={device}
            onChange={handleChangeDevice}
            fullWidth
            input={<Input />}
          >
            <MenuItem value="">
              <em>No selection</em>
            </MenuItem>
            <MenuItem value={1234124}>[1234124] Smart Sea container</MenuItem>
            <MenuItem value={4256235}>
              [4256235] Electric Vehicle Battery in VIN:123134311431
            </MenuItem>
            <MenuItem value={2341432}>
              [2341432] Winch drive on Maersk Matz
            </MenuItem>
            <MenuItem value={9768956}>
              [9768956] Conditionally and periodically unmanned bridge @ ABB
              test ship
            </MenuItem>
            <MenuItem value={4352353}>
              [4352353] Heating controller at Sponda HQ
            </MenuItem>
          </Select>
        </FormControl>
      </form>

      {device && device == "1234124" ? (
        <>
          <LogRecorder
            recorderName="Smart Sea container"
            loggerCert="Bluetooth device 23431"
          ></LogRecorder>
          <EventEntry
            eventId="112"
            triggerCert="Shipping company"
            loggerCert="Drive67"
            dataIngress="Manufacturing test and calibration data: 0x013424"
            dataDigest=""
            dataURL="https://asdsa"
            previousDigest=""
          ></EventEntry>
        </>
      ) : null}

      {device && device == "2341432" ? (
        <>
          <LogRecorder
            recorderName="Winch drive on Maersk Matz"
            loggerCert="ABB drive 1231"
          ></LogRecorder>
          <EventEntry
            eventId="112"
            triggerCert="ABB factory HKI"
            loggerCert="Drive67"
            dataIngress="Manufacturing test and calibration data: 0x013424"
            dataDigest=""
            dataURL="https://asdsa"
            previousDigest=""
          />
          <EventEntry
            eventId="113"
            triggerCert="john.doe@fi.abb.com"
            loggerCert="Drive67"
            dataIngress="Commissioned and registered the drive on starboard side stern deck winch at Maersk Matz. Installed new device certificate. Warranty period started."
            dataDigest=""
            dataURL="https://asdsa"
            previousDigest=""
          />
          <EventEntry
            eventId="114"
            triggerCert="alice@maersk.com"
            loggerCert="Drive67"
            dataIngress="Drive overload, forced despite warnings"
            dataDigest=""
            dataURL="https://asdsa"
            previousDigest=""
          />
          <EventEntry
            eventId="115"
            triggerCert="alice@maersk.com"
            loggerCert="Drive67"
            dataIngress="Console login, accessed log files"
            dataDigest=""
            dataURL="https://asdsa"
            previousDigest=""
          />
          <EventEntry
            eventId="116"
            triggerCert="Surveillance cam 63"
            loggerCert="Drive67"
            dataIngress="Captured photo"
            img="img/Computer_hacking.jpg"
            dataDigest=""
            dataURL="https://asdsa"
            previousDigest=""
          />
          <EventEntry
            eventId="117"
            triggerCert="bob@fi.abb.com"
            loggerCert="Drive67"
            dataIngress="Warranty maintenance and service inspection completed."
            dataDigest=""
            dataURL="https://asdsa"
            previousDigest=""
          />
        </>
      ) : null}

      {device && device == "9768956" ? (
        <>
          <LogRecorder
            recorderName="Conditionally and periodically unmanned bridge @ ABB test ship"
            loggerCert="ABB device 1231"
          ></LogRecorder>
          <EventEntry
            eventId="34245"
            triggerCert="Radar 1654"
            loggerCert="Drive67"
            dataIngress="Object detected on track, distance 2.7 nm"
            dataDigest=""
            dataURL="https://asdsa"
            previousDigest=""
          ></EventEntry>
          <EventEntry
            eventId="34246"
            triggerCert="AI Camera 24"
            loggerCert="Drive67"
            dataIngress="Object identified as an iceberg"
            dataDigest=""
            dataURL="https://asdsa"
            previousDigest=""
          ></EventEntry>
          <EventEntry
            eventId="34247"
            triggerCert="Bridge Intelligence 67"
            loggerCert="Drive67"
            dataIngress="Route recalculated"
            dataDigest=""
            dataURL="https://asdsa"
            previousDigest=""
          ></EventEntry>
          <EventEntry
            eventId="34248"
            triggerCert="Auto pilot device 12"
            loggerCert="Drive67"
            dataIngress="Adopting new route. Command: full starboard, decrease speed with 30%."
            dataDigest=""
            dataURL="https://asdsa"
            previousDigest=""
          ></EventEntry>
          <EventEntry
            eventId="34249"
            triggerCert="Bridge Intelligence 67"
            loggerCert="Drive67"
            dataIngress="Wake up the captain"
            dataDigest=""
            dataURL="https://asdsa"
            previousDigest=""
          ></EventEntry>
          <EventEntry
            eventId="34250"
            triggerCert="Bridge door access control"
            loggerCert="Drive67"
            dataIngress="The captain enters the bridge"
            dataDigest=""
            dataURL="https://asdsa"
            previousDigest=""
          ></EventEntry>
        </>
      ) : null}

      {device && device == "4256235" ? (
        <>
          <LogRecorder
            recorderName="Electric Vehicle Battery in VIN:123134311431"
            loggerCert="EV battery system 543"
          ></LogRecorder>
          <EventEntry
            eventId="112"
            triggerCert="Ionity charger 672"
            loggerCert="Drive67"
            dataIngress="Charging with 110 kW"
            dataDigest=""
            dataURL="https://asdsa"
            previousDigest=""
          ></EventEntry>
          <EventEntry
            eventId="112"
            triggerCert="Ionity charger 672"
            loggerCert="Drive67"
            dataIngress="Charging with 55 kW"
            dataDigest=""
            dataURL="https://asdsa"
            previousDigest=""
          ></EventEntry>
          <EventEntry
            eventId="112"
            triggerCert="Onboard ECU 78"
            loggerCert="Drive67"
            dataIngress="Preconditioning the vehicle with 8 kW, outdoor -25°C, coupé -27°C"
            dataDigest=""
            dataURL="https://asdsa"
            previousDigest=""
          ></EventEntry>
          <EventEntry
            eventId="112"
            triggerCert="Onboard ECU 78"
            loggerCert="Drive67"
            dataIngress="Driving, discharging with 150 kW at outdoor -25°C. State of charge level: 4%"
            dataDigest=""
            dataURL="https://asdsa"
            previousDigest=""
          ></EventEntry>
        </>
      ) : null}

      {device && device == "4352353" ? (
        <>
          <LogRecorder
            recorderName="Heating controller at Sponda HQ"
            loggerCert="Exigo 1453"
          ></LogRecorder>
          <EventEntry
            eventId="112"
            triggerCert="Regin factory"
            loggerCert="Drive67"
            dataIngress="Manufacturing test and calibration data: 0x013424"
            dataDigest=""
            dataURL="https://asdsa"
            previousDigest=""
          ></EventEntry>
          <EventEntry
            eventId="112"
            triggerCert="Energy company API"
            loggerCert="Drive67"
            dataIngress="Power demand peak forecasted +3h-+6h from now, temporarily focus your heating demand to those same hours, to achieve CHP synergy"
            dataDigest=""
            dataURL="https://asdsa"
            previousDigest=""
          ></EventEntry>
        </>
      ) : null}
    </div>
  );
}
