import { makeStyles } from "@material-ui/styles";

export default makeStyles(() => ({
  download: {
    position: "absolute",
    right: 20,
    bottom: 5,
  },
  certSummary: {
    display: "flex",
    flexFlow: "colummn wrap",
    justifyContent: "space-between",
    alignItems: "flex-start",
    alignContent: "flex-start",
  },
  certDates: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontSize: "80%",
    display: "inline",
    alignSelf: "flex-end",
    flexGrow: "2",
    flexBasis: "10%",
    margin: `0 5 0 5 px`,
  },
  certAttributes: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontSize: "70%",
    display: "inline",
    margin: "0px",
    padding: "0px",
  },
  BoxWithElapsedProgress: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  greenBox: {
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    display: "inline-flex",
    //backgroundColor: "green",
  },
  blueBox: {
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    display: "inline-flex",
    //backgroundColor: "blue",
    marginRight: "10px",
  },
  yellowBox: {
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    display: "inline-flex",
    //backgroundColor: "yellow",
    marginRight: "10px",
  },
  gridItemToAlignCenter: {
    display: "flex",
    alignItems: "stretch",
    alignContent: "center",
    //backgroundColor: "green",
  },
  flowVertical: {
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-start",
    alignItems: "flex-start",
  },
  circularProgress: {
    alignItems: "center",
    justifyContent: "center",
    display: "inline-flex",
    marginRight: "10px",
  },
  ChainLinkIcon: {
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  ownOfflineRoot: {
    //backgroundColor: "yellow",
  },
  externalRoot: {
    //backgroundColor: "pink",
  },
  selfSignedRoot: {
    //backgroundColor: "gray",
  },
  myselfNotRoot: {
    //backgroundColor: "blue",
  },
  watermark: {
    opacity: "0.25",
    color: "lightgrey",
    fontSize: "40px",
    fontWeight: "bold",
    zIndex: "0",
    position: "absolute",
    top: "5%",
    right: "5%",
    overflow: "hidden",
    lineHeight: "3em",
    letterSpacing: "2px",
  },
}));
