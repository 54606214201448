import React from "react";
import PropTypes from "prop-types";
import useStyles from "./styles";

import AccordionDetails from "@material-ui/core/AccordionDetails";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

export default function CertAccordionDetails(props) {
  const classes = useStyles();

  const { pem, parsed, serial } = props;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(pem);
  };

  const downloadPem = () => {
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(pem)
    );
    element.setAttribute("download", `${serial}.pem`);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <AccordionDetails>
      <pre>{parsed}</pre>
      <Tooltip title={`Copy PEM`}>
        <IconButton className={classes.copy} onClick={copyToClipboard}>
          <Icon>{"filter_none"}</Icon>
        </IconButton>
      </Tooltip>
      <Tooltip title={`Download PEM`}>
        <IconButton className={classes.download} onClick={downloadPem}>
          <Icon>{"cloud_download"}</Icon>
        </IconButton>
      </Tooltip>
    </AccordionDetails>
  );
}

CertAccordionDetails.propTypes = {
  parsed: PropTypes.string,
  pem: PropTypes.string,
  serial: PropTypes.string,
};
