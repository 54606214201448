import { makeStyles } from "@material-ui/styles";

export default makeStyles(() => ({
  download: {
    position: "absolute",
    right: 20,
    bottom: 5,
  },
  copy: {
    position: "absolute",
    right: 70,
    bottom: 5,
  },
}));
