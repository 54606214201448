import React from "react";
import PropTypes from "prop-types";
import useStyles from "./styles";
import useCardStyles from "assets/jss/cert-manager/components/cardStyle";
import Typography from "@material-ui/core/Typography";
import imgstep0 from "assets/img/auth0-create-tenant-step0.png";
import imgstep1 from "assets/img/auth0-create-tenant-step1.png";
import imgstep2 from "assets/img/auth0-create-tenant-step2.png";
import Button from "components/CustomButtons/Button.js";

export default function Step1(props) {
  const cardClasses = useCardStyles();
  const componentClasses = useStyles();
  const classes = { ...cardClasses, ...componentClasses };

  const { next, back } = props;

  const onSubmit = (event) => {
    event.preventDefault();
    next();
  };

  return (
    <div className={classes.inputDiv}>
      <form onSubmit={onSubmit}>
        <div className={classes.helpSectionContainer}>
          <div className={classes.helpEntryWrapper}>
            <img src={imgstep0} alt="step0" className={classes.helpImg} />
            <Typography>
              Use another browser window, and Login to Auth0, create an account
              if necessary.
              <Button target="_blank" href="https://www.auth0.com/">
                Open Auth0
              </Button>
              Click <b>LOG IN</b> at the top of the window.
            </Typography>
          </div>
          <div className={classes.helpEntryWrapper}>
            <img src={imgstep1} alt="step1" className={classes.helpImg} />
            <Typography>Create a new tentant for the CertManager</Typography>
          </div>
          <div className={classes.helpEntryWrapper}>
            <img src={imgstep2} alt="step2" className={classes.helpImg} />
            <Typography>
              Provide a describing name, it need to be globally unique. (Do NOT
              close the Auth0 window yet.)
            </Typography>
          </div>
        </div>

        <div>
          <Button onClick={back}>Back</Button>
          <Button type="submit" variant="contained" color="primary">
            NEXT
          </Button>
        </div>
      </form>
    </div>
  );
}

Step1.propTypes = {
  next: PropTypes.func,
  back: PropTypes.func,
};
