import React from "react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import LayoutOneCard from "components/LayoutOneCard/LayoutOneCard";

export default function TermsOfUse() {
  return (
    <LayoutOneCard>
      <Card>
        <CardHeader color="primary">CertManager Terms of Use</CardHeader>
        <CardBody>
          <h3>Your personal information</h3>
          Use of your personal information submitted to or via the Site is
          governed by our privacy policy.
          <h3>Intellectual property rights</h3>
          All intellectual property rights in any content of the Site (including
          text, scripts, graphics, software, photographs and other images,
          videos, sound, trade marks and logos) are owned by us or our
          licensors. Except as expressly set out here, nothing in these terms of
          use gives you any rights in respect of any intellectual property owned
          by us or our licensors and you acknowledge that you do not acquire any
          ownership rights by downloading content from the Site. If you print
          off, copy or store pages from the Site (only as permitted by these
          terms of use), you must ensure that any copyright, trade mark or other
          intellectual property right notices contained in the original content
          are reproduced.
          <h3>Content</h3>
          We may change the format and content of the Site from time to time.
          You agree that your use of the Site is on an ‘as is’ and ‘as
          available’ basis and at your sole risk. Whilst we try to make sure
          that all information contained on the Site (other than any
          user-generated content) is correct, it is not intended to amount to
          authority or advice on which reliance should be placed. You should
          check with us or the relevant information source before acting on any
          such information. WE MAKE OR GIVE NO REPRESENTATION OR WARRANTY AS TO
          THE ACCURACY, COMPLETENESS, CURRENCY, CORRECTNESS, RELIABILITY,
          INTEGRITY, QUALITY, FITNESS FOR PURPOSE OR ORIGINALITY OF ANY CONTENT
          OF THE SITE AND, TO THE FULLEST EXTENT PERMITTED BY LAW, ALL IMPLIED
          WARRANTIES, CONDITIONS OR OTHER TERMS OF ANY KIND ARE HEREBY EXCLUDED
          AND WE ACCEPT NO LIABILITY FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED
          AS A RESULT OF YOU OR ANYONE ELSE USING THE SITE OR RELYING ON ANY OF
          ITS CONTENT. We cannot and do not guarantee that any content of the
          Site will be free from viruses and/or other code that may have
          contaminating or destructive elements. It is your responsibility to
          implement appropriate IT security safeguards (including anti-virus and
          other security checks) to satisfy your particular requirements as to
          the safety and reliability of content. If you post or otherwise
          display any of your or your employer’s own content on the Site, you
          hereby grant ControlThings Oy Ab a non-exclusive, perpetual,
          royalty-free, paid-up, worldwide license, with right to sub-license,
          to copy. modify, distribute, display, perform, make, use and sell (or
          sub-license, as applicable) such content.
        </CardBody>
      </Card>
    </LayoutOneCard>
  );
}
