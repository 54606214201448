import React, { useState } from "react";
import PropTypes from "prop-types";

import useStyles from "./styles";
import useCardStyles from "assets/jss/cert-manager/components/cardStyle";

import { config } from "Constants";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormGroup from "@material-ui/core/FormGroup";
import RadioGroup from "@material-ui/core/RadioGroup";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";

//Icons
import HelpIcon from "icons/HelpIcon";
import DeviceIcon from "icons/DeviceIcon";
import RotateCertIcon from "icons/RotateCertIcon";
import M2M from "./M2M";
import { Typography } from "@material-ui/core";

export default function Device(props) {
  const { basepath, auth0domain } = props;

  const url = config.url.BACKEND;

  const cardClasses = useCardStyles();
  const componentClasses = useStyles();
  const classes = { ...cardClasses, ...componentClasses };

  const [server, setServer] = useState(true);
  const [client, setClient] = useState(true);

  const [registration, setRegistration] = useState(false);

  const [checked, setChecked] = useState([]);
  const [wontPrompt, setNoPrompting] = useState([
    "Site where device is installed",
    "Extended Warranty",
    "Commissioning completed date",
  ]);
  const [willPrompt, setPrompting] = useState([
    "Customer name",
    "Purchase date",
    "Distributor",
  ]);
  const unSelectedforPrompting = intersection(checked, wontPrompt);
  const selectedForPrompting = intersection(checked, willPrompt);

  function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }
  function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setPrompting(willPrompt.concat(wontPrompt));
    setNoPrompting([]);
  };

  const handleCheckedRight = () => {
    setPrompting(willPrompt.concat(unSelectedforPrompting));
    setNoPrompting(not(wontPrompt, unSelectedforPrompting));
    setChecked(not(checked, unSelectedforPrompting));
  };

  const handleCheckedLeft = () => {
    setNoPrompting(wontPrompt.concat(selectedForPrompting));
    setPrompting(not(willPrompt, selectedForPrompting));
    setChecked(not(checked, selectedForPrompting));
  };

  const handleAllLeft = () => {
    setNoPrompting(wontPrompt.concat(willPrompt));
    setPrompting([]);
  };

  const itemList = (items, heading) => (
    <Paper className={classes.paper}>
      <h6>{heading}</h6>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem
              size="small"
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon size="small">
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  size="small"
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} size="small" primary={`${value}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <Card chart>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>
          <DeviceIcon /> Device certification
        </h4>
        <p className={classes.cardCategoryWhite}>
          The factory equips the devices with individual bootstrap certs, which
          are getting replaced on initial use (Just-In-Time-Registration)
        </p>
      </CardHeader>
      <CardBody>
        <TextField
          label="Bootstrap certification (issued and installled by factory)"
          style={{ margin: 10 }}
          placeholder={`${url}${basepath}/device/issueBootstrapCert`}
          helperText="This CA service is not publicly available, only accessible in the factory environment. It is signing bootstrap certs."
          fullWidth
          disabled={true}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Typography variant="caption">
          You may use this CertManager on your production line for issuing
          bootstrap certs. In that case your production line need to use the
          credentials below. Alternatively you can import a whitelist of
          provisioned bootstrap certs, which you receive from your device
          manufacturer.
        </Typography>
        <Tooltip title="An outsourced manufacturer is issuing these certs, and provides a whitelist of certificates for delivered devices. You need to import this whitelist.">
          <IconButton>
            <HelpIcon />
          </IconButton>
        </Tooltip>
        <br />
        <M2M basepath={basepath} auth0domain={auth0domain} />
        <br />
        <TextField
          label="Device registration (at initial use)"
          style={{ margin: 10 }}
          placeholder={`${url}${basepath}/activateDevice`}
          helperText="The devices are replacing their bootstrap cert with final credentials when they appear online for the first time."
          fullWidth
          disabled={true}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Tooltip title="Just-In-Time-Registration: device gets final credential which replaces the bootstrap cert. Their bootstrap cert need to be included in the imported whitelist of bootstrap certificates.">
          <IconButton>
            <HelpIcon />
          </IconButton>
        </Tooltip>
        <br />
        {process.env.NODE_ENV === "development" ? (
          <FormControl component="fieldset">
            <RadioGroup
              style={{ margin: 10 }}
              margin="normal"
              name="registrationtype"
              value={registration}
              onChange={() => setRegistration(!registration)}
            >
              <FormControlLabel
                value="prompted"
                control={<Switch disabled name="addCustomAttributes" />}
                label="Interactive device registration. The commissioner need to provide custom data for device registration"
              />

              <FormHelperText>
                <small>
                  The data provided by commissioner is stored in device
                  certificate (tamper protected)
                </small>
              </FormHelperText>
            </RadioGroup>
            <Grid
              container
              spacing={2}
              justify="center"
              alignItems="center"
              className={classes.root}
            >
              <Grid item>{itemList(wontPrompt, "Do not prompt")}</Grid>
              <Grid item>
                <Grid container direction="column" alignItems="center">
                  <IconButton
                    variant="outlined"
                    size="small"
                    className={classes.button}
                    onClick={handleAllRight}
                    disabled={wontPrompt.length === 0}
                    aria-label="move all right"
                  >
                    ≫
                  </IconButton>
                  <IconButton
                    variant="outlined"
                    size="small"
                    className={classes.button}
                    onClick={handleCheckedRight}
                    disabled={unSelectedforPrompting.length === 0}
                    aria-label="move selected right"
                  >
                    &gt;
                  </IconButton>
                  <IconButton
                    variant="outlined"
                    size="small"
                    className={classes.button}
                    onClick={handleCheckedLeft}
                    disabled={selectedForPrompting.length === 0}
                    aria-label="move selected left"
                  >
                    &lt;
                  </IconButton>
                  <IconButton
                    variant="outlined"
                    size="small"
                    className={classes.button}
                    onClick={handleAllLeft}
                    disabled={willPrompt.length === 0}
                    aria-label="move all left"
                  >
                    ≪
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item>
                {itemList(willPrompt, "Include in device certs")}
              </Grid>
            </Grid>
          </FormControl>
        ) : null}
        <br />
        <TextField
          label="Registered devices will automatically renew their own cert on this address"
          style={{ margin: 10 }}
          placeholder={`${url}${basepath}/rotateCert`}
          helperText="The devices are authenticating with their old cert (bootstrap is not sufficicent). Other cert attributes are not affected, except validity dates."
          margin="normal"
          disabled={true}
          InputLabelProps={{
            shrink: true,
            startadornment: (
              <InputAdornment position="start">
                <RotateCertIcon />
              </InputAdornment>
            ),
          }}
        />
        {process.env.NODE_ENV === "development" ? (
          <TextField
            label="Validity period (days)"
            type="number"
            margin="normal"
            disabled={true}
            defaultValue="730"
            style={{ margin: 10 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        ) : null}
        {process.env.NODE_ENV === "development" ? (
          <>
            <br />
            <FormControl
              component="fieldset"
              margin="normal"
              style={{ margin: 10 }}
            >
              <FormHelperText>Device certs capabilities</FormHelperText>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={server}
                      onChange={() => setServer(!server)}
                      disabled={true}
                      name="server"
                    />
                  }
                  label="TLS server"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={client}
                      onChange={() => setClient(!client)}
                      disabled={true}
                      name="client"
                    />
                  }
                  label="TLS client"
                />
              </FormGroup>
            </FormControl>
          </>
        ) : null}
      </CardBody>
      {/** <CardFooter>
        <AccessTime /> 2319 issued certificates
      </CardFooter> */}
    </Card>
  );
}

Device.propTypes = {
  basepath: PropTypes.string,
  auth0domain: PropTypes.string,
};
