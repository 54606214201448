import { useState } from "react";
import getApi from "./api";
import useApiParams from "services/backend/useApiParams";

export default function useRevokeCert() {
  const [revokedCert, setRevokedCert] = useState();
  const [error, setError] = useState(false);
  const { params } = useApiParams();

  const revokeCert = (serialhex, reason) => {
    if (params) {
      postRevokeCert(params, serialhex, reason)
        .then((res) => {
          setRevokedCert(res);
        })
        .catch((err) => {
          setError(err);
        });
    } else {
      setError("No api token");
    }
  };

  return { revokedCert, revokeCert, error };
}

export function postRevokeCert(apiParams, serialhex, reason) {
  const api = getApi(apiParams);
  return new Promise((resolve, reject) => {
    api
      .post("/revoke", { serialhex, reason })
      .then((res) => {
        if (res.error) {
          reject(res.error);
        }
        if (res.data.error) {
          reject(res.data.error);
        }
        resolve(res.data[0]);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
