import React, { useState } from "react";

import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import NewInstanceDialog from "../../layouts/newInstanceDialog/NewInstanceDialog";
import EditConfDialog from "./editConf/EditConf";

export default function Admin() {
  const [openInstanceDialog, setOpenInstanceDialog] = useState(false);
  const [openEditConfDialog, setOpenEditConfDialog] = useState(false);
  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Button onClick={() => setOpenEditConfDialog(true)} color="primary">
            Edit conf file
          </Button>
        </GridItem>
      </GridContainer>
      <NewInstanceDialog
        open={openInstanceDialog}
        onClose={() => setOpenInstanceDialog(false)}
      />
      <EditConfDialog
        open={openEditConfDialog}
        onClose={() => setOpenEditConfDialog(false)}
      />
    </>
  );
}
