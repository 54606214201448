import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PrivateRoute from "components/Route/PrivateRoute";
//import { createBrowserHistory } from "history";

import Main from "layouts/Main.js";
import LandingPage from "layouts/LandingPage.js";
import MyInstances from "layouts/MyInstances.js";
import useInstances from "services/backend/useInstances";
import Privacy from "infoSites/Privacy";
import TermsOfUse from "infoSites/TermsOfUse";

//const hist = createBrowserHistory();

export default function AppRouter() {
  const { instances, error } = useInstances(false, false);
  useEffect(() => {
    if (error) {
      console.log("Get instances error", error);
    }
  }, [error]);
  return (
    <>
      {instances.length !== 0 ? (
        <BrowserRouter>
          <Switch>
            {instances.map((instance, key) => (
              <PrivateRoute
                key={key}
                path={instance.basepath}
                component={Main}
              />
            ))}
            <PrivateRoute path="/myinstances" component={MyInstances} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/terms" component={TermsOfUse} />
            <Route path="/" render={(props) => <LandingPage {...props} />} />
          </Switch>
        </BrowserRouter>
      ) : null}
    </>
  );
}
