import getApi from "./api";

export default function parseCSR(apiParams, csr) {
  const api = getApi(apiParams);
  return new Promise((resolve, reject) => {
    api
      .post("/parseCSR", { csr })
      .then((res) => {
        if (res.error) {
          reject(res.error);
        }
        if (res.data.error) {
          reject(res.data.error);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
