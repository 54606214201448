import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import useStyles from "./styles";
import useCardStyles from "assets/jss/cert-manager/components/cardStyle";
import CustomInput from "components/CustomInput/CustomInput.js";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import countries from "./countries";
import Switch from "@material-ui/core/Switch";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "components/CustomButtons/Button.js";
import useInstances from "services/backend/useInstances";

export default function Step0(props) {
  const cardClasses = useCardStyles();
  const componentClasses = useStyles();
  const classes = { ...cardClasses, ...componentClasses };

  const { step, setStep, next } = props;
  const { instances } = useInstances();
  const [basepath, setBasepath] = useState("");
  const [commonName, setCommonName] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [country, setCountry] = useState(countries[68]);
  const [demo, setDemo] = useState(false);
  const [notUniqBasePath, setNotUniqBasePath] = useState(false);
  const [instanceArray, setInstanceArray] = useState([]);

  useEffect(() => {
    if (instances) {
      const a = [];
      instances.forEach((instance) => {
        a.push(instance.basepath);
      });
      setInstanceArray(a);
    }
  }, [instances]);

  useEffect(() => {
    if (step) {
      setBasepath(step.basepath);
      setCommonName(step.commonName);
      setOrganizationName(step.organizationName);
      setCountry(step.country);
      setDemo(step.demo);
    }
  }, [step]);

  const onSubmit = (event) => {
    event.preventDefault();
    if (instanceArray.includes(`/${basepath}`)) {
      setNotUniqBasePath(true);
      return;
    }
    const _step = { ...step };
    _step.basepath = basepath;
    _step.commonName = commonName;
    _step.organizationName = organizationName;
    _step.country = country;
    _step.demo = demo;
    setStep(_step);
    next();
  };

  const countryToFlag = (isoCode) => {
    return typeof String.fromCodePoint !== "undefined"
      ? isoCode
          .toUpperCase()
          .replace(/./g, (char) =>
            String.fromCodePoint(char.charCodeAt(0) + 127397)
          )
      : isoCode;
  };

  return (
    <div className={classes.inputDiv}>
      <form onSubmit={onSubmit}>
        <CustomInput
          labelText={notUniqBasePath ? "Try another basepath" : "Basepath"}
          formControlProps={{ fullWidth: true }}
          onChange={(value) => {
            setBasepath(value);
          }}
          inputProps={{
            error: notUniqBasePath,
            required: true,
          }}
        />
        <FormHelperText className={classes.helperText}>
          Like: https://certmanager.controlthings.fi/
          <b>mybasepath</b>
        </FormHelperText>
        <CustomInput
          labelText="Common Name"
          formControlProps={{ fullWidth: true }}
          onChange={(value) => {
            setCommonName(value);
          }}
          inputProps={{
            required: true,
          }}
        />
        <FormHelperText>
          An identifying name for my new Cert Manager
        </FormHelperText>
        <CustomInput
          labelText="My Organization"
          formControlProps={{ fullWidth: true }}
          onChange={(value) => {
            setOrganizationName(value);
          }}
          inputProps={{
            required: true,
          }}
        />
        <Autocomplete
          className={classes.countrySelect}
          options={countries}
          autoHighlight
          getOptionLabel={(option) => option.label}
          renderOption={(option) => (
            <>
              <span>{countryToFlag(option.code)}</span>
              {option.label} ({option.code})
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Country"
              className={classes.countryEntry}
              inputProps={{
                required: true,
                ...params.inputProps,
              }}
            />
          )}
          value={country}
          onChange={(event, data) => {
            setCountry(data);
          }}
        />
        {process.env.NODE_ENV === "development" ? (
          <FormControlLabel
            className={classes.switchLabel}
            control={<Switch checked={demo} onChange={() => setDemo(!demo)} />}
            label="Public visible demo"
          />
        ) : null}
        <div>
          <Button type="submit" variant="contained" color="primary">
            NEXT
          </Button>
        </div>
      </form>
    </div>
  );
}

Step0.propTypes = {
  step: PropTypes.object,
  setStep: PropTypes.func,
  next: PropTypes.func,
};
