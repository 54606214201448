import React, { useRef, useState } from "react";
import PropTypes from "prop-types";

import storeReceivedCert from "services/backend/storeReceivedCert";
import useApiParams from "services/backend/useApiParams";

import Dropzone from "react-dropzone-uploader";
import Preview from "./components/Preview";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

export default function CustomDropzone(props) {
  const { updateStatus } = props;

  const { params: apiParams } = useApiParams();

  const rootCRL = useRef();
  const certManagerCert = useRef();
  const offlineRootCert = useRef();

  const [open, setOpen] = useState(false);
  const info = useRef();

  const setRootCRL = (str) => {
    rootCRL.current = str;
  };

  const setCertManagerCert = (str) => {
    certManagerCert.current = str;
  };

  const setOfflineRootCert = (str) => {
    offlineRootCert.current = str;
  };

  const handleSubmit = (files, allFiles) => {
    info.current = null;
    if (!offlineRootCert.current) {
      info.current = "No Root cert";
      setOpen(true);
      return;
    }
    if (!certManagerCert.current) {
      info.current = "No cert manager cert";
      setOpen(true);
      return;
    }
    storeReceivedCert(
      apiParams,
      certManagerCert.current,
      offlineRootCert.current,
      undefined,
      rootCRL.current,
      true
    )
      .then((res) => {
        if (res == "OK") {
          allFiles.forEach((f) => f.remove());
          rootCRL.current = null;
          certManagerCert.current = null;
          offlineRootCert.current = null;
          updateStatus();
        } else {
          info.current = "Did not add certificates to cert manager";
          setOpen(true);
        }
      })
      .catch((error) => {
        console.log("storeReceivedCert error", error);
        info.current = "Did not add certificates to cert manager";
        setOpen(true);
      });
  };

  const preview = (props) => {
    return (
      <Preview
        {...props}
        setRootCRL={setRootCRL}
        setCertManagerCert={setCertManagerCert}
        setOfflineRootCert={setOfflineRootCert}
      />
    );
  };

  /*
   */

  return (
    <>
      <Dropzone
        onSubmit={handleSubmit}
        PreviewComponent={preview}
        maxFiles={3}
        inputContent="Drop CertManagerCert, RootCert and RootCRL here"
        inputWithFilesContent={(files) =>
          `expecting ${3 - files.length} more file${
            3 - files.length > 1 ? "s" : ""
          }`
        }
        submitButtonDisabled={(files) => files.length < 2}
        submitButtonContent="Store the CertManager CRT, CRL and Root CRT"
        styles={{
          dropzone: {
            overflow: "hidden",
          },
          inputLabel: {
            color: "gray",
          },
          dropzoneActive: {
            backgroundColor: "lightgray",
            borderColor: "#C76700",
          },
          submitButton: {
            color: "white",
            backgroundColor: "#C76700",
          },
          inputLabelWithFiles: {
            color: "gray",
          },
        }}
      />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>{info.current}</DialogContent>
      </Dialog>
    </>
  );
}

CustomDropzone.propTypes = {
  updateStatus: PropTypes.func,
};
