import React from "react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import LayoutOneCard from "components/LayoutOneCard/LayoutOneCard";

export default function Privacy() {
  return (
    <LayoutOneCard>
      <Card>
        <CardHeader color="primary">Privacy and cookies notice</CardHeader>
        <CardBody>
          Prepared on 21.05.2021. Last modified 21.05.2021.
          <h3>1. The Registrar</h3>
          ControlThings Oy Ab Mestarintie 14 06150 Porvoo Finland
          <h3>2. Contact person responsible for the register</h3>
          Kristian Bäckström, kristian.backstrom@controlthings.fi +358405166116
          <h3>3. Name of the register</h3>
          CertManager database
          <h3>4. Legal basis and purpose of the processing of personal data</h3>
          The legal basis for the processing of personal data under the EU
          General Data Protection Regulation is consent of the person
          (documented, voluntary, identified, informed and unambiguous).
          <br />
          The purpose of processing personal information is to provide a user
          authentication service, to provide evaluation and demonstration of the
          authentication service, to provide a verifiable event logging service,
          to provide evaluation and demonstration of the verifiable event
          logging service, to communicate with customers, to maintain a customer
          relationship, and to market. The data is not used for automated
          decision making or profiling.
          <h3>5. Information content of the register</h3>
          The information stored in the register includes: person's name,
          company / organization, (e-mail address, address), website addresses,
          network IP address, IDs / profiles on social media services,
          information about subscribed services and their changes, billing
          information, other customer relationship and subscribed services
          information. The IP addresses of the website visitors and the cookies
          necessary for the functions of the service are processed on the basis
          of a legitimate interest, e.g. to ensure data security and to collect
          statistics about visitors to the Site in cases where they may be
          considered personal data. If necessary, the consent of third-party
          cookies will be requested separately.
          <h3>6. Regular sources of information</h3>
          The information stored in the register is obtained from the customer
          e.g. content uploaded via web forms, e-mail, telephone, via social
          media services, contracts, customer meetings and other situations in
          which the customer discloses their information. Contact information
          for companies and other organizations can also be collected from
          public sources such as websites, directory services, and other
          companies.
          <h3>
            7. Regular transfers of data and transfers of data outside the EU or
            the EEA
          </h3>
          The information is not regularly disclosed to other parties. The
          information may be published to the extent agreed with the customer.
          Data may also be transferred by the registrar outside the EU or the
          EEA. Data will not be transferred to the United States without the
          express consent of the data subjects. If you transfer personal data to
          different parties, please indicate here the potential recipients or
          groups of recipients (including processors / subcontractors), the
          purposes for which the personal data will be processed and the reasons
          for the transfer if the data is transferred outside the EU.
          <h3>8. Registry Security Principles</h3>
          The register shall be handled with due care and the data processed by
          the information systems shall be adequately protected. When registry
          information is stored on Internet servers, the physical and digital
          security of their hardware is adequately addressed. The registrar
          shall ensure that the data stored, as well as the access rights to the
          servers and other information critical to the security of personal
          data, are treated confidentially and only by the employees whose job
          description it includes.
          <h3>
            9. Right of inspection and right to request correction of
            information
          </h3>
          Every person in the register has the right to check the information
          stored in the register and to request the correction of any incorrect
          information or the completion of incomplete information. If a person
          wishes to check the data stored about him or her or request a
          correction, the request must be sent in writing to the data registrar.
          If necessary, the registrar may ask the applicant to prove his or her
          identity. The registrar will respond to the customer within the time
          limit set by the EU Data Protection Regulation (generally within one
          month).
          <h3>10. Other rights related to the processing of personal data</h3>A
          person in the register has the right to request the removal of his or
          her personal data from the register ("the right to be forgotten").
          Data subjects also have other rights under the EU's general data
          protection regulation, such as restrictions on the processing of
          personal data in certain situations. Requests must be sent in writing
          to the registrar. If necessary, the registrar may ask the applicant to
          prove his or her identity. The registrar will respond to the customer
          within the time limit set by the EU Data Protection Regulation
          (generally within one month).
        </CardBody>
      </Card>
    </LayoutOneCard>
  );
}
