import React, { useState } from "react";
import PropTypes from "prop-types";

import useStyles from "./styles";
import useCardStyles from "assets/jss/cert-manager/components/cardStyle";

import { config } from "Constants";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionActions from "@material-ui/core/AccordionActions";
import Button from "components/CustomButtons/Button.js";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";

//Icons
import ExpandMoreIcon from "@material-ui/icons/ExpandMoreOutlined";
import Auth0Icon from "icons/Auth0Icon";
import AppUserIcon from "@material-ui/icons/AssignmentInd";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import AccessTime from "@material-ui/icons/AccessTime";

export default function User(props) {
  const cardClasses = useCardStyles();
  const componentClasses = useStyles();
  const classes = { ...cardClasses, ...componentClasses };

  const { basepath } = props;

  const url = config.url.BACKEND;

  const [rbac, setRbac] = useState(true);
  const [server, setServer] = useState(true);
  const [client, setClient] = useState(true);

  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>
          <AppUserIcon className={classes.headerIcon} /> User certification
        </h4>
        <p className={classes.cardCategoryWhite}>
          Users are automatically obtaining personal certs, by first
          authenticating using Auth0. Users get separate certs for every app /
          mobile device they are using.
        </p>
      </CardHeader>
      <CardBody>
        <Accordion className={classes.nestedSettingAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1c-content"
            disabled={true}
            id="panel1c-header"
          >
            <Auth0Icon />
            <div className={classes.column}>
              <Typography className={classes.heading}>
                Auth0 settings - how to login
              </Typography>
              <Typography className={classes.secondaryHeading}>
                This authentication is required for accessing the CA signing
                service
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl
              component="fieldset"
              margin="normal"
              style={{ margin: 10 }}
            >
              <FormHelperText>
                {`
              Sign up and sign in to Auth0. Create a new
              application, you can name it 'CT Cert Manager'.
              `}
              </FormHelperText>
              <TextField
                label="Domain"
                style={{ margin: 10 }}
                placeholder="ReplaceWithYourAuth0TenantID.eu.auth0.com"
                helperText="Domain, from your Auth0 application settings"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="Client ID"
                style={{ margin: 10 }}
                placeholder="Paste your Client ID here"
                helperText="Client ID, from your Auth0 application settings"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="Client secret"
                style={{ margin: 10 }}
                placeholder="Paste your Client Secret here"
                helperText="Client Secret, from your Auth0 application settings"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Divider />

              <FormHelperText>
                {`
              Use Role Based Permissions. Example permissions:
              'read:messages' 'write:vote' or
              'read:messages|write:vote|create:invoice'. See
              https://www.youtube.com/watch?v=_MW113gd2ho and
              https://youtu.be/iCa3MCPm1mY for more info
              `}
              </FormHelperText>
              <FormControlLabel
                control={
                  <Switch
                    checked={rbac}
                    onChange={() => setRbac(!rbac)}
                    name="rbac"
                  />
                }
                label="Role based permission scopes. Include permissions from tokens into the issued certificates"
              />
              <FormHelperText>
                Paste these into your Auth0 application configuration:
              </FormHelperText>
              <TextField
                label="Allowed Callback URLs"
                style={{ margin: 10 }}
                placeholder="https://<this-base-url>"
                disabled
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <FileCopyOutlinedIcon />
              <TextField
                label="Allowed Logout URLs"
                style={{ margin: 10 }}
                placeholder="https://<this-base-url>"
                disabled
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <FileCopyOutlinedIcon />
              <TextField
                label="Allowed Web Origins"
                style={{ margin: 10 }}
                placeholder="https://<this-base-url>"
                disabled
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <FileCopyOutlinedIcon />
              <TextField
                label="Allowed Origins (CORS)"
                style={{ margin: 10 }}
                placeholder="https://<this-base-url>"
                disabled
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <FileCopyOutlinedIcon />
            </FormControl>
          </AccordionDetails>
          <AccordionActions>
            <Button size="sm">Cancel</Button>
            <Button size="sm" color="primary">
              Save
            </Button>
          </AccordionActions>
        </Accordion>

        <TextField
          label="This is the Auth0 protected URL from where your App Users are obtaining their certs"
          style={{ margin: 10 }}
          placeholder={`${url}${basepath}/app/issue`}
          helperText="Anyone able to log in will get a certificate"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        {process.env.NODE_ENV === "development" ? (
          <>
            <TextField
              label="Validity period (days)"
              type="number"
              margin="normal"
              defaultValue="10"
              disabled={true}
              style={{ margin: 10 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <br />
            <FormControl
              component="fieldset"
              margin="normal"
              style={{ margin: 10 }}
            >
              <FormHelperText>User cert capabilities</FormHelperText>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={server}
                      onChange={() => setServer(!server)}
                      disabled={true}
                      name="server"
                    />
                  }
                  label="TLS server"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={client}
                      onChange={() => setClient(!client)}
                      disabled={true}
                      name="client"
                    />
                  }
                  label="TLS client"
                />
              </FormGroup>
            </FormControl>
          </>
        ) : null}
      </CardBody>
      {/** <CardFooter>
        <AccessTime /> 121 issued certificates
      </CardFooter> */}
    </Card>
  );
}

User.propTypes = {
  basepath: PropTypes.string,
};
