import { makeStyles } from "@material-ui/styles";

export default makeStyles(() => ({
  unsupported: {
    color: "red",
    alignSelf: "flex-start",
    margin: "10px 3%",
    fontFamily: "Helvetica",
  },
  normal: {
    alignSelf: "flex-start",
    margin: "10px 3%",
    fontFamily: "Helvetica",
  },
}));
