import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import DropdownButton from "components/CustomButtons/DropdownButton";
import Icon from "@material-ui/core/Icon";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import IconButton from "@material-ui/core/IconButton";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUserOutlined";
import CertificateIcon from "icons/CertificateIcon";
import Tooltip from "@material-ui/core/Tooltip";
import Identicon from "react-identicons";
import CertificateRotatedIcon from "icons/CertificateRotatedIcon";
import CertificateRevokedIcon from "icons/CertificateRevokedIcon";
import useRevokeCert from "services/backend/useRevokeCert";
import useStyles from "./styles";
import { getCertPEM } from "services/backend/useCertPEM";
import useApiParams from "services/backend/useApiParams";
import { removeFromExtension } from "./Parser";
import CertAccordionDetails from "components/Cert/CertAccordionDetails";

export default function CertificateAccordion(props) {
  const classes = useStyles();

  const { cert: initialCert } = props;

  const { params: apiParams } = useApiParams();
  const { revokeCert, revokedCert } = useRevokeCert();

  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const [cert, setCert] = useState();
  const [rawCert, setRawCert] = useState();

  useEffect(() => {
    if (initialCert) {
      setCert(initialCert);
    }
  }, [initialCert]);

  useEffect(() => {
    if (revokedCert) {
      setCert(revokedCert);
    }
  }, [revokedCert]);

  const revoke = (reason) => {
    revokeCert(cert.serialhex, reason);
  };

  const handleExpand = async () => {
    console.log("expand");
    if (!accordionExpanded && !rawCert) {
      try {
        const result = await getCertPEM(apiParams, cert.serialhex);
        console.log("expand res", result);
        setRawCert(result);
      } catch (error) {
        console.log("get cert Error", error);
      }
    }

    setAccordionExpanded(!accordionExpanded);
  };

  return (
    <>
      {cert ? (
        <Accordion
          className={classes.cert}
          TransitionProps={{ unmountOnExit: true }}
          expanded={accordionExpanded}
        >
          <AccordionSummary>
            <IconButton onClick={handleExpand}>
              <Icon>{accordionExpanded ? "expand_more" : "chevron_right"}</Icon>
            </IconButton>
            {cert.status == "V" ? (
              <div className={classes.statusValid}>
                <CertificateIcon />
                <VerifiedUserIcon /> <small>valid</small>{" "}
              </div>
            ) : null}
            {cert.status == "E" ? (
              <div className={classes.statusExpired}>
                <CertificateIcon />
                <CertificateRotatedIcon /> <small>expired</small>{" "}
              </div>
            ) : null}
            {cert.status == "R" ? (
              <div className={classes.statusRevoked}>
                <CertificateIcon />
                <CertificateRevokedIcon />{" "}
                <small>
                  revoked {moment.unix(cert.revocationdate).fromNow()}{" "}
                  {cert.revocationreason
                    ? cert.revocationreason === "null"
                      ? ", unspecified reason"
                      : cert.revocationreason
                    : null}
                </small>{" "}
              </div>
            ) : null}
            <div className={classes.paperHeadingInactive}>
              Subject Key{" "}
              <Tooltip title={`Subject Key ID: ${cert.subject_key_identifier}`}>
                <IconButton>
                  <Identicon
                    size="16"
                    string={cert.subject_key_identifier}
                    className={classes.certSerialIcon}
                  />
                </IconButton>
              </Tooltip>
            </div>
            <div className={classes.paperHeading}>
              Cert ID{" "}
              <Tooltip title={`Serial number: ${cert.serialhex}`}>
                <IconButton>
                  <Identicon
                    size="16"
                    string={cert.serialhex}
                    className={classes.certSerialIcon}
                  />
                </IconButton>
              </Tooltip>
              <br />
              Issuer: {cert.issuer_common_name}
            </div>
            <div className={classes.paperHeading}>
              {removeFromExtension(cert.extensions["1.2.246.10.23378962.10.6"])}{" "}
              {removeFromExtension(cert.extensions["1.2.246.10.23378962.10.7"])}
              <br />
              {removeFromExtension(
                cert.extensions["1.2.246.10.23378962.10.15"]
              )}{" "}
              {removeFromExtension(
                cert.extensions["1.2.246.10.23378962.10.16"]
              )}
              {removeFromExtension(cert.extensions["1.2.246.10.23378962.10.1"])}{" "}
              {removeFromExtension(cert.extensions["1.2.246.10.23378962.10.2"])}{" "}
              {removeFromExtension(cert.extensions["1.2.246.10.23378962.10.3"])}{" "}
              {removeFromExtension(cert.extensions["1.2.246.10.23378962.10.4"])}
            </div>
            <div className={classes.paperDateInactive}>
              Effective {moment.unix(cert.not_before).format("D.M.YYYY")} -{" "}
              {moment.unix(cert.not_after).format("D.M.YYYY")}
            </div>
            <DropdownButton
              options={[
                {
                  name:
                    "Cert holder's privKey has been stolen or lost (compromised)",
                  click: () => {
                    revoke("keyCompromise");
                  },
                },
                {
                  name: "CA's privKey has been stolen or lost (compromised)",
                  click: () => {
                    revoke("cACompromise");
                  },
                },
                {
                  name: "The affiliation has changed",
                  click: () => {
                    revoke("affiliationChanged");
                  },
                },
                {
                  name: "Decommissioned, no longer to be used",
                  click: () => {
                    revoke("cessationOfOperation");
                  },
                },
                {
                  name: "Temporarily suspend, put on hold",
                  click: () => {
                    revoke("certificateHold");
                  },
                },
              ]}
              width={70}
            />
          </AccordionSummary>
          {rawCert ? (
            <CertAccordionDetails
              pem={rawCert.pem}
              parsed={rawCert.parsed}
              serial={cert.serialhex}
            />
          ) : null}
        </Accordion>
      ) : null}
    </>
  );
}

CertificateAccordion.propTypes = {
  cert: PropTypes.any,
};
