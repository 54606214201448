import React from "react";
import PropTypes from "prop-types";
import useStyles from "../styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import bash from "react-syntax-highlighter/dist/esm/languages/hljs/bash";
import docco from "react-syntax-highlighter/dist/esm/styles/hljs/docco";
import Typography from "@material-ui/core/Typography";
import Button from "components/CustomButtons/Button.js";
import CustomDropzone from "./components/CustomDropzone/CustomDropzone";
import { DialogActions } from "@material-ui/core";

SyntaxHighlighter.registerLanguage("bash", bash);

export default function RotateOfflineRootCertDialog(props) {
  const classes = useStyles();

  const { open, onClose, common_name, downloadCSR, updateStatus } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ className: classes.dialog }}
    >
      <DialogContent>
        <Typography variant="h5">Cert Rotation Procedure</Typography>
        <Typography variant="subtitle1">to be executed periodically</Typography>
        <Typography variant="body2">
          When 50% of the issued CertManager cert validity period have elapsed,
          the CertManager will ask you to renew the certificate. Here is what
          you should do.
        </Typography>
        <Typography variant="caption">
          Underlying certs on the field can continue to use their known root
          cert for another 10 years, this will not break anything.
        </Typography>
        <ol>
          <li>
            Load the <em>toBeSecurelyArchived/</em> folder from the offline
            archive to your computer. (You might prefer to keep your computer
            offline while you have the root priv key loaded on your computer)
          </li>
          <li>
            Download a fresh CSR, and put it into the <em>rotateRequestCSR/</em>{" "}
            folder
            <br />
            <Button onClick={() => downloadCSR(common_name)} color="primary">
              Download CSR
            </Button>
          </li>
          <li>
            Execute the script
            <SyntaxHighlighter language="bash" style={docco}>
              sh periodicalRotationUsingOfflineRoot.sh
            </SyntaxHighlighter>
          </li>
          <li>
            Upload the output files to this CertManager:
            <ol>
              <li>The updated root CRL (revocation list)</li>
              <li>The updated root certificate</li>
              <li>The updated CertManager certificate</li>
            </ol>
          </li>
        </ol>
        <CustomDropzone onClose={onClose} updateStatus={updateStatus} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() =>
            window.open(
              "https://www.youtube.com/watch_popup?v=ogiyDiD52l8",
              "newWindow",
              "width=640,height=360"
            )
          }
          color="primary"
        >
          Watch Tutorial
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

RotateOfflineRootCertDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  common_name: PropTypes.string,
  downloadCSR: PropTypes.func,
  updateStatus: PropTypes.func,
};
