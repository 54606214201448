import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import useFetchUser from "services/backend/useFetchUser";

export default function Approved({ component: Component, ...rest }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isApproved, setIsApproved] = useState(false);

  const { id, error } = useFetchUser();

  useEffect(() => {
    if (error) {
      setIsLoading(false);
      console.log("Fetch user error", error);
    }
  }, [error]);

  useEffect(() => {
    if (id === "unauthorized") {
      setIsLoading(false);
    }
    if (id) {
      setIsLoading(false);
      setIsApproved(true);
    }
  }, [id]);

  const getComponent = () => {
    if (isLoading) {
      return <div>Loading...</div>;
    }
    if (error) {
      return <div>{`${error}`}</div>;
    }
    if (isApproved) {
      return <Component {...rest} />;
    }
    return <div>Not approved for this domain</div>;
  };

  return getComponent();
}

Approved.propTypes = {
  component: PropTypes.any,
};
