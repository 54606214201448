import getApi from "./api";

export default function createInstance(
  apiParams,
  basepath,
  countryName,
  organizationName,
  commonName,
  demo,
  mgmtClient,
  author
) {
  const api = getApi(apiParams);
  return new Promise((resolve, reject) => {
    api
      .post("/createInstance", {
        basepath,
        countryName,
        organizationName,
        commonName,
        demo,
        mgmtClient,
        author,
      })
      .then((res) => {
        if (res.error) {
          reject(res.error);
        }
        if (res.data.error) {
          reject(res.data.error);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
