import { makeStyles } from "@material-ui/styles";

export default makeStyles(() => ({
  dialog: {
    //border: "#FF0000 1px solid",
  },
  dialogContent: {
    padding: "50",
    margin: "30px",
  },
}));
