import { useState, useEffect, useCallback } from "react";
import getApi from "./api";
import useApiParams from "services/backend/useApiParams";

export default function useCertSubjectCount() {
  const { params } = useApiParams();
  const [appCount, setAppCount] = useState(false);
  const [deviceCount, setDeviceCount] = useState(false);
  const [subCaCount, setSubCaCount] = useState(false);
  const [error, setError] = useState(false);

  const update = useCallback(() => {
    if (params) {
      getCertSubjectCount(params)
        .then((res) => {
          res.forEach((element) => {
            switch (element.subject_type) {
              case "app":
                setAppCount(element.count);
                break;
              case "device":
                setDeviceCount(element.count);
                break;
              case "subca":
                setSubCaCount(element.count);
                break;

              default:
                break;
            }
          });
        })
        .catch((err) => {
          setError(err);
        });
    }
  }, [params]);

  useEffect(() => {
    update();
  }, [update, params]);

  return { appCount, deviceCount, subCaCount, update, error };
}

export function getCertSubjectCount(apiParams) {
  const api = getApi(apiParams);
  return new Promise((resolve, reject) => {
    api
      .get("/getCertSubjectCount")
      .then((res) => {
        if (res.error) {
          reject(res.error);
        }
        if (res.data.error) {
          reject(res.data.error);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
