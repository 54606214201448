import { useState, useEffect, useCallback } from "react";
import getApi from "./api";
import useApiParams from "services/backend/useApiParams";

export default function useCertPem(serialhex) {
  const [pem, setPem] = useState();
  const [parsed, setParsed] = useState();
  const [error, setError] = useState(false);
  const { params } = useApiParams();

  const update = useCallback(() => {
    if (params) {
      getCertPEM(params, serialhex)
        .then((res) => {
          setPem(res.setPem);
          setParsed(res.parsed);
        })
        .catch((err) => {
          setError(err);
        });
    }
  }, [params]);

  useEffect(() => {
    update();
  }, [update, params]);

  return { pem, parsed, update, error };
}

export function getCertPEM(apiParams, serialhex) {
  const api = getApi(apiParams);
  return new Promise((resolve, reject) => {
    api
      .post("/getCertPEM", { serialhex })
      .then((res) => {
        if (res.error) {
          reject(res.error);
        }
        if (res.data.error) {
          reject(res.data.error);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
