import React from "react";
import PropTypes from "prop-types";
import GridItem from "components/Grid/GridItem.js";
import MaterialTable from "material-table";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
//import DropdownButton from "components/CustomButtons/DropdownButton";
import Avatar from "@material-ui/core/Avatar";
import CertificateAccordion from "./CertificateAccordion";
import useApiParams from "services/backend/useApiParams";
import putCommonNameOnHold from "services/backend/putCommonNameOnHold";
import resumeCommonNameFromHold from "services/backend/resumeCommonNameFromHold";
import useStyles from "./styles";
import PauseIcon from "icons/PauseIcon";

export default function CertificateTable(props) {
  const classes = useStyles();

  const { certs, columns, update: getNewCerts, commonNameIcon, filter } = props;
  const { params } = useApiParams();
  const handelOnHold = (certs) => {
    const paused = certs[0].paused;
    const commonName = certs[0].subject_common_name;
    if (paused) {
      resumeCommonNameFromHold(params, commonName)
        .then(() => {
          getNewCerts();
        })
        .catch((err) => {
          console.log("resumeCommonNameFromHold error", err);
        });
    } else {
      putCommonNameOnHold(params, commonName)
        .then(() => {
          getNewCerts();
        })
        .catch((err) => {
          console.log("putCommonNameOnHold error", err);
        });
    }
  };

  const componentsAction = (data) => {
    const { certs } = data.data;
    const paused = certs[0].paused;
    return (
      <Tooltip title={paused ? "Resume all certs" : "Put all certs on hold"}>
        <IconButton onClick={() => handelOnHold(certs)}>
          {paused ? <PauseIcon /> : <Icon>{"more_horiz"}</Icon>}
        </IconButton>
      </Tooltip>
    );
  };

  const detailPanelRender = (rowData) => {
    // rowData = certs returned by subject record
    const certHistory = rowData.certs;
    return (
      <GridItem className={classes.cert}>
        {certHistory.map((cert, index) => (
          <CertificateAccordion key={index} cert={cert} /> // TODO: TransitionProps={{ unmountOnExit: true }}
        ))}
      </GridItem>
    );
  };

  const renderAvatar = (rowData) => (
    <Avatar
      size={40}
      round="true"
      src={
        rowData === undefined && !commonNameIcon
          ? commonNameIcon
            ? commonNameIcon
            : " "
          : rowData.avatar
      }
    >
      {commonNameIcon}
    </Avatar>
  );

  return (
    <MaterialTable
      options={{
        showTitle: false,
        actionsColumnIndex: -1,
      }}
      columns={[
        {
          render: renderAvatar,
        },
        ...columns,
      ]}
      data={Object.values(certs).reduce((result, subject) => {
        const {
          commonName,
          maxExpire,
          validCount,
          totalCount,
        } = subject.inAllCerts;

        const add = () => {
          const { regPhase, ...rest } = subject.extra;
          let phase = regPhase;
          switch (regPhase) {
            case "Bootstrap Unregistered":
              phase = "Not yet activated";
              break;
            case "Registered device":
              phase = "Activated";
              break;
            default:
              break;
          }
          result.push({
            ...rest,
            regPhase: phase,
            commonName: commonName,
            expire: moment.unix(maxExpire).fromNow(),
            certCount: `${validCount} valid, ${totalCount} total`,
            certs: subject.certs,
          });
        };

        // If no filter is activated add all certificate!
        if (filter && filter.length > 0) {
          if (filter.includes(subject.extra.regPhase)) {
            add();
          }
          if (filter.includes("revoked")) {
            subject.certs.every((cert) => {
              if (cert.status === "R") {
                add();
                return false;
              }
              return true;
            });
          }
        } else {
          add();
        }

        return result;
      }, [])}
      actions={[
        //Don't remove, components Action overrides this if removed you will get a warning
        {
          icon: "",
          onClick: () => {},
        },
      ]}
      components={{
        Action: componentsAction,
      }}
      detailPanel={[
        {
          icon: "chevron_right",
          tooltip: "Certificate details",
          render: detailPanelRender,
        },
      ]}
      localization={{ header: { actions: "" } }}
    />
  );
}

CertificateTable.propTypes = {
  certs: PropTypes.object,
  columns: PropTypes.array,
  update: PropTypes.func,
  commonNameIcon: PropTypes.node,
  filter: PropTypes.array,
};
