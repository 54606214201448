import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import config from "auth_config.json";

export default function useApiParams() {
  const {
    isAuthenticated,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
  } = useAuth0();
  const [token, setToken] = useState(false);
  const [basePath, setBasePath] = useState(false);
  const [params, setParams] = useState(false);
  const [error, setError] = useState(false);

  let location = useLocation();
  useEffect(() => {
    if (token && basePath) {
      setParams({ token, path: basePath });
    }
  }, [token, basePath]);

  useEffect(() => {
    if (location) {
      setBasePath(`/${location.pathname.split("/")[1]}`);
    }
  }, [location]);

  useEffect(() => {
    const getAccessToken = async () => {
      let accessToken;

      try {
        accessToken = await getAccessTokenSilently({
          audience: config.audience,
        });

        setToken(accessToken);
      } catch (err) {
        console.log("token error", err.error);

        if (
          err.error === "consent_required" ||
          err.error === "login_required"
        ) {
          accessToken = await getAccessTokenWithPopup({
            audience: config.audience,
          });
          setToken(accessToken);
        } else {
          setError(err);
        }
      }
    };
    if (basePath && isAuthenticated) {
      //console.log("authenticated", isLoading);
      getAccessToken();
    }
  }, [
    isAuthenticated,
    basePath,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
  ]);

  return { params, token, basePath, error };
}
