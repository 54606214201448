import React, { useEffect, useState } from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import Divider from "@material-ui/core/Divider";
import Fab from "components/CustomButtons/Fab";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";

import Button from "components/CustomButtons/Button.js";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import BusinessIcon from "@material-ui/icons/Business";

import awsimg from "assets/img/register-CA-to-aws-iot-core.jpg";
import useSubCACerts from "../../../services/backend/useSubCACerts";
import useStyles from "../styles";

import CertificateTable from "../components/CertificateTable";
import CSRDialog from "./csrDialog/CSRDialog";

export default function Partners() {
  const columns = [
    { title: "Partner", field: "commonName" },
    { title: "Certs", field: "certCount" },
    { title: "Last cert expires", field: "expire" },
  ];

  const classes = useStyles();
  const {
    certs: partnerCerts,
    update: updatePartnerCerts,
    error: deviceError,
  } = useSubCACerts();
  const [certs, setCerts] = useState({});

  useEffect(() => {
    const subjectCerts = {};
    partnerCerts.forEach((cert) => {
      let currentCert = subjectCerts[cert.subject_common_name];
      if (!currentCert) {
        // no such cert found, initiate an element
        currentCert = { inAllCerts: {}, certs: [], extra: {} };
        currentCert.inAllCerts.totalCount = 0;
        currentCert.inAllCerts.validCount = 0;
      }
      currentCert.inAllCerts.commonName = cert.subject_common_name;
      currentCert.inAllCerts.totalCount++;
      if (!cert.expired && cert.status == "V") {
        currentCert.inAllCerts.validCount++;
      }
      if (
        !currentCert.inAllCerts.maxExpire ||
        currentCert.inAllCerts.maxExpire < cert.not_after
      ) {
        currentCert.inAllCerts.maxExpire = cert.not_after;
      }
      if (cert.not_before)
        if (
          !currentCert.inAllCerts.mostRecent ||
          currentCert.inAllCerts.mostRecent < cert.not_before
        ) {
          currentCert.inAllCerts.mostRecent = cert.not_before;
        }
      currentCert.certs.push(cert);
      subjectCerts[cert.subject_common_name] = currentCert;
    });
    setCerts(subjectCerts);
  }, [partnerCerts]);

  useEffect(() => {
    if (deviceError) {
      console.log("device error", deviceError);
    }
  }, [deviceError]);

  const [awsDialogOpen, setAWSOpen] = React.useState(false);
  const [partnerDialogOpen, setPartnerOpen] = React.useState(false);
  const [csrDialog, setCSRDialog] = React.useState(false);

  const handleCSRDialogOpen = () => {
    setCSRDialog(true);
  };
  const handleCSRDialogClose = () => {
    setCSRDialog(false);
  };

  const handleAWSClose = () => {
    setAWSOpen(false);
  };

  const handleClickAWSOpen = () => {
    setAWSOpen(true);
  };

  const handleClickPartnerDialogOpen = () => {
    setPartnerOpen(true);
  };

  const handlePartnerDialogClose = () => {
    setPartnerOpen(false);
  };

  return (
    <GridContainer>
      <GridItem>
        <Fab
          variant="extended"
          color="primary"
          aria-label="add"
          onClick={handleCSRDialogOpen}
        >
          <AddIcon />
          Incoming CSR request
        </Fab>
        <CSRDialog open={csrDialog} onClose={() => handleCSRDialogClose()} />
        {/**
        <Fab
          variant="extended"
          color="primary"
          aria-label="add"
          onClick={handleClickPartnerDialogOpen}
        >
          <AddIcon />
          Invite partner org
        </Fab>
        <Dialog
          open={partnerDialogOpen}
          onClose={handlePartnerDialogClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Certify a partner to manage staff / equipment certs
          </DialogTitle>
          <DialogContent>
            <DialogContentText className={classes.dialog}>
              You can certify your partners in two alternative ways. An invited
              partner is a subordinated Certificate Authority.
              <h4>Option 1: Invite link (recommended)</h4>
              Create a link that the recipient can use for starting using an own
              Cert Manager, as a subordinated CA to this Cert Manager.
              <br />
              <TextField
                autoFocus
                size="small"
                margin="dense"
                id="name"
                label="Recipient name"
                type="text"
                fullidth
              />
              <br />
              <Button size="sm" variant="outlined" color="primary">
                Create link
              </Button>
              <br />
              Note. You will be prompted to confirm the SubCa certificate
              details the recipient is asking you to certify.
              <Divider />
              <h4>Option 2: Manual certification</h4>
              <ul>
                <li>
                  Upload a Certificate Signing Request provided by your partner{" "}
                  <Button size="sm" variant="outlined" color="primary">
                    Select CSR to upload
                  </Button>
                </li>
                <li>
                  Download the Certificate file issued based on the CSR
                  <Button size="sm" variant="outlined" color="primary">
                    Issue Cert
                  </Button>
                </li>
                <li>Send the Certificate file to your partner</li>
              </ul>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handlePartnerDialogClose} color="primary">
            Not yet implemented!
            </Button>
          </DialogActions>
        </Dialog>
         */}
        {process.env.NODE_ENV === "development" ? (
          <>
            <Fab
              variant="extended"
              color="primary"
              aria-label="add"
              onClick={handleClickAWSOpen}
            >
              <AddIcon />
              Add AWS IoT Core
            </Fab>
            <Dialog
              open={awsDialogOpen}
              onClose={handleAWSClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">
                Connect your devices with AWS IoT Core
              </DialogTitle>
              <DialogContent>
                <Grid container>
                  <Grid item>
                    <img src={awsimg} alt="..." width="60%" />
                  </Grid>
                  <Grid item>
                    <DialogContentText className={classes.dialog}>
                      In order to connect your certified devices with AWS IoT
                      Core, your AWS environment need to anchor trust to this
                      Certificate Authority by registering it in AWS.
                      <ol>
                        <li>
                          Open another browser window, and{" "}
                          <a href="https://console.aws.amazon.com/console/">
                            sign to your AWS cloud console
                          </a>
                        </li>
                        <ul>
                          <li>Navigate to Services and select IoT Core</li>
                          <li>
                            From the left hand menu, expand Secure and select
                            CAs
                          </li>
                          <li>
                            Click the Register button, and then Register CA
                          </li>
                        </ul>
                        <li>
                          {`
                      Copy the line from "Step 2: Copy this registration code"
                      `}
                        </li>
                        <li>
                          <TextField
                            autoFocus
                            size="small"
                            margin="dense"
                            id="name"
                            label="Paste the registration code here"
                            type="text"
                            fullWidth
                          />
                        </li>
                        <li>
                          <Button size="sm" variant="outlined" color="primary">
                            Press here to sign
                          </Button>
                        </li>
                        <li>
                          Click{" "}
                          <Button size="sm" variant="outlined" color="primary">
                            Download CA certificate{" "}
                          </Button>
                          , and upload it to AWS
                        </li>
                        <li>
                          Click{" "}
                          <Button size="sm" variant="outlined" color="primary">
                            Download Verification certificate
                          </Button>
                          , and upload it to AWS
                        </li>
                      </ol>
                    </DialogContentText>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleAWSClose} color="primary">
                  Not yet implemented!
                </Button>
              </DialogActions>
            </Dialog>
          </>
        ) : null}
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>
              <BusinessIcon /> Certified partner organisations
            </h4>
            <p className={classes.cardCategoryWhite}>
              These trusted organisations are authorized to certify their own
              staff / equipment into the ecosystem. You have the power to revoke
              the organisations anytime.
            </p>
          </CardHeader>
          <CardBody>
            <CertificateTable
              certs={certs}
              columns={columns}
              update={updatePartnerCerts}
              commonNameIcon={<BusinessIcon />}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
