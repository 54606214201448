import React from "react";
import PropTypes from "prop-types";

import useStyles from "./styles";
import Footer from "components/Footer/Footer.js";

import logo from "assets/img/3d-logo-heavily-left-small.png";

export default function LayoutOneCard({ children }) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.contentWrapper}>
        <img src={logo} alt="logo" />
        {children}
      </div>
      <Footer />
    </>
  );
}

LayoutOneCard.propTypes = {
  children: PropTypes.element.isRequired,
};
