import React from "react";
import PropTypes from "prop-types";
import useStyles from "./styles";
import useCardStyles from "assets/jss/cert-manager/components/cardStyle";
import Typography from "@material-ui/core/Typography";
import CustomInput from "components/CustomInput/CustomInput.js";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "components/CustomButtons/Button.js";
import Switch from "@material-ui/core/Switch";
import issuePartnerCert from "services/backend/issuePartnerCert";
import useApiParams from "services/backend/useApiParams";

export default function Step2(props) {
  const cardClasses = useCardStyles();
  const componentClasses = useStyles();
  const classes = { ...cardClasses, ...componentClasses };

  const { step, setStep, next, back, step0 } = props;

  const { params: apiParams } = useApiParams();
  const { csr } = step0;

  const onSubmit = (event) => {
    event.preventDefault();
    issuePartnerCert(apiParams, csr, {})
      .then(
        ({
          issuedCert: receivedCert,
          chainToRoot: receivedChain,
          root: receivedRoot,
        }) => {
          console.log(
            "received partner cert + bundled chain to root, first cert is recently issued, the rest are the chain to root",
            "cert " + receivedCert,
            "chain " + receivedChain,
            "root " + receivedRoot
          );
          const _step = { ...step };
          if (receivedCert) {
            // Contains at least the issued cert + issuer
            _step.issuedCertPEM = receivedCert;
            _step.chainBundlePEM = receivedChain;
            _step.rootPEM = receivedRoot;
            setStep(_step);
            next();
          }
        }
      )
      .catch((error) => {
        console.error("issuePartnerCert error", error);
      });
  };

  return (
    <div className={classes.inputDiv}>
      <form onSubmit={onSubmit}>
        {process.env.NODE_ENV === "development" ? (
          <>
            <div>
              <div>
                <Typography>
                  Adjust the parameters for this partner. Max-depth, cert
                  length, detailed certifying permissions
                </Typography>
              </div>
            </div>

            <div>
              <FormControlLabel
                control={
                  <CustomInput
                    labelText="365"
                    formControlProps={{ fullWidth: false }}
                    inputProps={{
                      required: false,
                    }}
                  />
                }
                label="Certificate validity (days)"
              />
              <FormHelperText>
                Permission to subordinate own partners
              </FormHelperText>
              <FormControlLabel
                control={<Switch />}
                label="Allow receiver to issue CA certs"
              />
              <FormHelperText>
                Permission to subordinate own partners, and not being limited to
                issuing entity certs (for devices and users)
              </FormHelperText>
            </div>
          </>
        ) : null}

        <div>
          <Button onClick={back}>Back</Button>
          <Button type="submit" variant="contained" color="primary">
            Sign the certificate
          </Button>
        </div>
      </form>
    </div>
  );
}

Step2.propTypes = {
  step: PropTypes.object,
  setStep: PropTypes.func,
  next: PropTypes.func,
  back: PropTypes.func,
  step0: PropTypes.object,
};
