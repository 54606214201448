import { useState, useEffect, useCallback } from "react";
import getApi from "./api";
import useApiParams from "services/backend/useApiParams";

export default function useInstanceCSR() {
  const { params } = useApiParams();
  const [CSR, setCSR] = useState();
  const [error, setError] = useState(false);

  const update = useCallback(() => {
    if (params) {
      getInstanceCSR(params)
        .then((res) => {
          setCSR(res);
        })
        .catch((err) => {
          setError(err);
        });
    }
  }, [params]);

  useEffect(() => {
    update();
  }, [update]);

  return { CSR, update, error };
}

export function getInstanceCSR(apiParams) {
  const api = getApi(apiParams);

  return new Promise((resolve, reject) => {
    api
      .get("/getInstanceCSR")
      .then((res) => {
        if (res.error) {
          reject(res.error);
        }
        if (res.data.error) {
          reject(res.data.error);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
