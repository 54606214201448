import React, { useState } from "react";
import PropTypes from "prop-types";

import useStyles from "./styles";
import useCardStyles from "assets/jss/cert-manager/components/cardStyle";

import useInstanceCSR from "services/backend/useInstanceCSR";
import useApiParams from "services/backend/useApiParams";

import Button from "components/CustomButtons/Button.js";
import selfSign from "services/backend/selfSign";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import TextField from "@material-ui/core/TextField";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Typography from "@material-ui/core/Typography";
import storeReceivedCert from "services/backend/storeReceivedCert";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import js from "react-syntax-highlighter/dist/esm/languages/hljs/javascript";
import plaintext from "react-syntax-highlighter/dist/esm/languages/hljs/javascript";
import docco from "react-syntax-highlighter/dist/esm/styles/hljs/docco";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import FilterNone from "@material-ui/icons/FilterNone";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import opensslConfURL from "assets/scripts/setupOfflineRootCA/toBeSecurelyArchived/helperFiles/openssl.conf";
import setupScriptURL from "assets/scripts/setupOfflineRootCA/setupOfflineRootCA.sh";
import rotationScriptURL from "assets/scripts/setupOfflineRootCA/toBeSecurelyArchived/helperFiles/do-not-use-this-yet.sh";
import CustomDropzone from "./components/CustomDropzone/CustomDropzone";
import RotateOfflineRootCertDialog from "../rotateCert/rotateOfflineRootCertDialog/RotateOfflineRootCertDialog";
import { Divider, Paper } from "@material-ui/core";

SyntaxHighlighter.registerLanguage("javascript", js);
SyntaxHighlighter.registerLanguage("plaintext", plaintext);

export default function ConfigurationUncomplete({ updateStatus, ...rest }) {
  const cardClasses = useCardStyles();
  const componentClasses = useStyles();
  const classes = { ...cardClasses, ...componentClasses };

  const { common_name, basepath, country_name, organization_name } = rest;

  const { params: apiParams } = useApiParams();
  const { CSR } = useInstanceCSR();

  const [caType, setCaType] = useState("selfsign");
  const [rootKeyType, setRootKeyType] = useState("offline");
  const [myCert, setMyCert] = useState();
  const [myChain, setMyChain] = useState();
  const [root, setRoot] = useState();
  const [
    showRotateOfflineRootCertDialog,
    setShowRotateOfflineRootCertDialog,
  ] = useState(false);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const handleCaType = (event, newCAType) => {
    setCaType(newCAType);
  };
  const handleSetRootKeyType = (event, newRootKeyType) => {
    setRootKeyType(newRootKeyType);
  };
  const handleSelfSign = () => {
    selfSign(apiParams, CSR)
      .then(() => {
        updateStatus();
      })
      .catch((err) => {
        console.log("Self Sign error", err);
      });
  };
  const handleStoreChain = () => {
    if (myCert && root) {
      storeReceivedCert(apiParams, myCert, root, myChain)
        .then((res) => {
          if (res == "OK") {
            updateStatus();
            console.log(
              "The cert was successfully stored, redirect to configured mode!"
            );
          }
        })
        .catch((err) => {
          console.log("store cert error", err);
        });
    } else {
      console.log("please provide both cert and root");
    }
  };

  const downloadOfflineRootScripts = async () => {
    let opensslConf = await (await fetch(opensslConfURL)).text();
    let setupScript = await (await fetch(setupScriptURL)).text();
    let rotationScript = await (await fetch(rotationScriptURL)).text();
    opensslConf = opensslConf.replaceAll("##basepath##", basepath);
    opensslConf = opensslConf.replaceAll(
      "##organization_name##",
      organization_name
    );
    opensslConf = opensslConf.replaceAll("##country_name##", country_name);

    setupScript = setupScript.replaceAll("##commonName##", common_name);
    setupScript = setupScript.replaceAll(
      "##organization_name##",
      organization_name
    );
    setupScript = setupScript.replaceAll("##country_name##", country_name);

    rotationScript = rotationScript.replaceAll("##commonName##", common_name);
    rotationScript = rotationScript.replaceAll(
      "##organization_name##",
      organization_name
    );
    rotationScript = rotationScript.replaceAll(
      "##country_name##",
      country_name
    );

    const zip = JSZip();
    zip.folder("toBeSecurelyArchived");
    zip.folder("toBeSecurelyArchived/helperFiles");
    zip.file("toBeSecurelyArchived/helperFiles/openssl.conf", opensslConf);
    zip.file(
      "toBeSecurelyArchived/helperFiles/do-not-use-this-yet.sh",
      rotationScript
    );
    zip.file("toBeSecurelyArchived/helperFiles/CertManager.csr", CSR);
    zip.file("setupOfflineRootCA.sh", setupScript);
    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, "setupOfflineRoot.zip");
    });
  };

  const downloadCSR = () => {
    const blob = new Blob([CSR], {
      type: "text/plain;charset=utf-8",
    });
    saveAs(blob, `CertManager-${common_name}.csr`);
  };

  return (
    <>
      <Typography variant="h4">Finalize the setup: Obtain a Cert</Typography>
      {CSR ? (
        <>
          <ToggleButtonGroup
            value={caType}
            exclusive
            onChange={handleCaType}
            aria-label="CA Type"
            className={classes.padBelow}
          >
            <ToggleButton value="selfsign" aria-label="Self-Signed root">
              Create a new private ecosystem
            </ToggleButton>
            <ToggleButton value="subca" aria-label="Subordinated CA">
              Join an existing Ecosystem
            </ToggleButton>
          </ToggleButtonGroup>

          {caType == "selfsign" ? (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Typography variant="h6">
                  Select strategy for storing the secret Root key
                </Typography>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <ToggleButtonGroup
                  value={rootKeyType}
                  exclusive
                  onChange={handleSetRootKeyType}
                  aria-label="Root key strategy"
                >
                  <ToggleButton
                    value="offline"
                    aria-label="Offline archived root key"
                  >
                    Offline (More secure)
                  </ToggleButton>
                  <ToggleButton value="online" aria-label="Online root key">
                    CertManager hosted (Easier)
                  </ToggleButton>
                </ToggleButtonGroup>
              </GridItem>
              {rootKeyType == "offline" ? (
                <>
                  <GridItem xs={12} sm={12} md={12}>
                    <Button
                      onClick={() =>
                        window.open(
                          "https://www.youtube.com/watch_popup?v=A24wXWIfutQ",
                          "newWindow",
                          "width=640,height=360"
                        )
                      }
                      color="primary"
                    >
                      Watch Tutorial
                    </Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Typography variant="caption" className={classes.padBelow}>
                      This is the{" "}
                      <b>
                        recommended root key management praxis for production
                        systems.
                      </b>
                    </Typography>
                    <Typography variant="caption" className={classes.padBelow}>
                      With the root private key securely archived offline,{" "}
                      <em>it is possible to recover</em> from scenarios where
                      the CertManager (or any other operational CA) private key
                      gets compromised, by revoking that certificate and issuing
                      a new certificate to the legitime CertManager. Every
                      participant in the ecosystem shall have the root key
                      pre-installed in firmware, app software, etc.
                    </Typography>
                    <Typography variant="h5">
                      Initiate a new OFFLINE Root CA
                    </Typography>
                    <Paper elevation={3} className={classes.additionalInfoBox}>
                      <Typography variant="subtitle1">
                        Offline root scripts
                      </Typography>
                      <br />
                      <Typography variant="caption">
                        Prerequsities: openssl, nodejs, bash
                      </Typography>
                      <Divider />
                      <Typography variant="caption">
                        <strong>
                          The zip archive contains scripts autoconfigured to
                        </strong>
                        <ol>
                          <li>
                            <strong>Create an OFFLINE Root CA</strong>
                            <ol>
                              <li>Generate a new root CA key</li>
                              <li>
                                Issue a self-signed root cert (20 years
                                validity)
                              </li>
                              <li>
                                Initialize the CRL revocation list for the root
                                (CRL to be hosted by CertManager)
                              </li>
                              <li>
                                Issue a cert for this CertManager (the tailored
                                script contains the required CSR request). This
                                CertManager becomes a subordinated online CA,
                                responsible for the operational use.
                              </li>
                            </ol>
                          </li>
                          <li>
                            <strong>Renew old CA certs and CRL</strong>
                            <ol>
                              <li>
                                Guide and assist you with future cert rotations
                                and updating of root CRL list
                              </li>
                            </ol>
                          </li>
                        </ol>
                      </Typography>
                      <Button
                        onClick={downloadOfflineRootScripts}
                        color="primary"
                      >
                        Download offline root scripts
                      </Button>
                    </Paper>
                    <Typography variant="body2">
                      To complete your CertManager setup, you need to:
                      <ol>
                        <li>
                          <strong>Download and extract</strong> the
                          setupOfflineRoot-{common_name}.zip file
                        </li>
                        <li>
                          <strong>Execute</strong> the script
                          <SyntaxHighlighter language="bash" style={docco}>
                            sh setupOfflineRootCA.sh
                          </SyntaxHighlighter>
                        </li>
                        <li>
                          <strong>Upload the output</strong> files of the
                          previous command from <em>uploadToCertManager/</em> to
                          to the drop zones below
                          <ol>
                            <li>The new root CRL (revocation list)</li>
                            <li>The new ecosystem root certificate</li>
                            <li>The new CertManager certificate</li>
                          </ol>
                        </li>
                        <li>
                          <b>
                            Take good care of the <em>toBeSecurelyArchived/</em>{" "}
                            folder
                          </b>
                          , you should prefer to store it offline in a secure
                          place. The folder contains the rootPrivateKey.pem,
                          which essential for the whole ecosystem security. You
                          will need it again for periodical CRL renewal and cert
                          renewal, and in case you need to revoke your
                          CertManager.
                          <br />
                          <Button
                            onClick={() =>
                              setShowRotateOfflineRootCertDialog(true)
                            }
                          >
                            See the instructions
                          </Button>
                          <RotateOfflineRootCertDialog
                            open={showRotateOfflineRootCertDialog}
                            onClose={() =>
                              setShowRotateOfflineRootCertDialog(false)
                            }
                            common_name={common_name}
                            downloadSCR={downloadCSR}
                          />
                        </li>
                      </ol>
                    </Typography>
                    <CustomDropzone updateStatus={updateStatus} />
                  </GridItem>
                </>
              ) : (
                <>
                  <GridItem xs={12} sm={12} md={12}>
                    <Button
                      onClick={() =>
                        window.open(
                          "https://www.youtube.com/watch_popup?v=8Pl9VBLjIEg",
                          "newWindow",
                          "width=640,height=360"
                        )
                      }
                      color="primary"
                    >
                      Watch Tutorial
                    </Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Typography variant="caption" className={classes.padBelow}>
                      This is the quickest and easiest way to get going.
                    </Typography>
                    <Typography variant="h5">
                      This CertManager will be an ONLINE Root CA
                    </Typography>
                    <ol>
                      <li>
                        Start using CertManager with online hosted root key. It
                        is just one click away. The CertManager will
                        automatically take care of root cert renewals.
                      </li>
                    </ol>
                    <Typography variant="body2">
                      <em>
                        Disclaimer: this approach is{" "}
                        <b>not recommended for production use</b>, please
                        consider to mitigate the risk for compromised root key!
                      </em>
                    </Typography>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Button onClick={handleSelfSign} color="primary">
                      Self-Sign and Become Root
                    </Button>
                  </GridItem>
                </>
              )}
            </GridContainer>
          ) : (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Button
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/watch_popup?v=ewB9mUdgYO0",
                      "newWindow",
                      "width=640,height=360"
                    )
                  }
                  color="primary"
                >
                  Watch Tutorial
                </Button>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Typography variant="h6">
                  Here is your Request to join
                </Typography>
                <Typography variant="body2" className={classes.padBelow}>
                  This CertManager will become a subordinated certificate
                  authority in the target PKI ecosystem.
                </Typography>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <Typography variant="body2">
                  <b>1. Send this CSR request</b> to someone who can authorize
                  you into the desired ecosystem
                </Typography>
                {/** 
                <pre className={classes.tiny}>{CSR}</pre> */}
                <Tooltip
                  title={`Copy the Signing Request PEM (CSR) to clipboard`}
                >
                  <IconButton
                    className={classes.copy}
                    onClick={() => copyToClipboard(CSR)}
                  >
                    <FilterNone />
                  </IconButton>
                </Tooltip>
                <Button onClick={downloadCSR} color="primary">
                  Download CSR TO SEND
                </Button>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Typography variant="body2">
                  <b>2. When you get your certificate</b> from the ecosystem
                  authority, continue with these steps.
                </Typography>
                <Typography variant="body2">
                  The authority should issue a PEM formatted certificate (based
                  on your signing request). Paste it in the field below
                </Typography>
                <TextField
                  className={classes.multilineTextField}
                  label="My Received Certificate (in PEM format)"
                  multiline
                  rows={5}
                  fullWidth
                  placeholder="Paste the received cert for this CertManager (PEM)"
                  variant="outlined"
                  onChange={(event) => {
                    setMyCert(event.target.value);
                  }}
                />
                <Typography variant="body2">
                  The authority should also provide a cert bundle containing the
                  chain of PEM formatted certificates up to the ecosystem root.
                  Paste it here
                </Typography>
                <TextField
                  className={classes.multilineTextField}
                  label="A Chain of CA Certs, your anchoring to the Root (in PEM format)"
                  multiline
                  rows={5}
                  fullWidth
                  placeholder="Paste the received chain to root PEM"
                  variant="outlined"
                  onChange={(event) => {
                    setMyChain(event.target.value);
                  }}
                />
                <Typography variant="body2">
                  The authority should also provide the ecosystem root. Paste it
                  here
                </Typography>
                <TextField
                  className={classes.multilineTextField}
                  label="Ecosystem Root Certificate (in PEM format)"
                  multiline
                  rows={5}
                  fullWidth
                  placeholder="Paste the received root PEM"
                  variant="outlined"
                  onChange={(event) => {
                    setRoot(event.target.value);
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <Button onClick={handleStoreChain} color="primary">
                  Store My Received Cert + Chain To Root + Ecosystem Root Cert
                </Button>
              </GridItem>
            </GridContainer>
          )}
        </>
      ) : null}
    </>
  );
}

ConfigurationUncomplete.propTypes = {
  updateStatus: PropTypes.func,
};
