import { makeStyles } from "@material-ui/styles";

export default makeStyles(() => ({
  formControl: {
    margin: 1,
    minWidth: 350,
  },
  sameRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  header: {
    display: "flex",
  },
  logoutButton: {
    marginLeft: "auto",
  },
}));
