import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ReactPlayer from "react-player/youtube";
import Button from "components/CustomButtons/Button.js";
import useStyles from "./styles";

export default function VideoDialog(props) {
  const { title, url, onClose, open, image, imageAlt } = props;
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.videoDialog}
      maxWidth={false}
      fullWidth={true}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={classes.videoContainer}>
        <ReactPlayer
          className={classes.reactPlayer}
          url={url}
          height="667px"
          width="300px"
        />
        <img src={image} alt={imageAlt} width="500px" />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
VideoDialog.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
