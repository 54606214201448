import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import useStyles from "./styles";
import useCardStyles from "assets/jss/cert-manager/components/cardStyle";
import Typography from "@material-ui/core/Typography";
import CustomInput from "components/CustomInput/CustomInput.js";
import FormHelperText from "@material-ui/core/FormHelperText";
import imgstep3 from "assets/img/auth0-create-tenant-step3.png";
import imgstep4 from "assets/img/auth0-create-tenant-step4.png";
import imgstep5 from "assets/img/auth0-create-tenant-step5.png";
import imgstep6 from "assets/img/auth0-create-tenant-step6.png";
import Button from "components/CustomButtons/Button.js";

export default function Step2(props) {
  const cardClasses = useCardStyles();
  const componentClasses = useStyles();
  const classes = { ...cardClasses, ...componentClasses };

  const { step, setStep, back } = props;

  const [auth0Domain, setAuth0Domain] = useState("");
  const [mgmtClientId, setMgmgtClientId] = useState("");
  const [mgmtClientSecret, setMgmgtClientSecret] = useState("");

  useEffect(() => {
    if (step) {
      setAuth0Domain(step.auth0Domain);
      setMgmgtClientId(step.mgmtClientId);
      setMgmgtClientSecret(step.mgmtClientSecret);
    }
  }, [step]);

  const onSubmit = (event) => {
    event.preventDefault();
    const _step = { ...step };
    _step.auth0Domain = auth0Domain;
    _step.mgmtClientId = mgmtClientId;
    _step.mgmtClientSecret = mgmtClientSecret;
    setStep(_step);
  };

  return (
    <div className={classes.inputDiv}>
      <form onSubmit={onSubmit}>
        <div className={classes.helpSectionContainer}>
          <div className={classes.helpEntryWrapper}>
            <img src={imgstep3} alt="step3" className={classes.helpImg} />
            <Typography>
              Create a new Application in Auth0. It will be used by CertManager.
            </Typography>
          </div>
          <div className={classes.helpEntryWrapper}>
            <img src={imgstep4} alt="step4" className={classes.helpImg} />
            <Typography>
              Provide a describing name, such as
              <i> CertManager API Integration</i>. Select
              <b> Machine to Machine</b> as the Application type.
            </Typography>
          </div>
          <div className={classes.helpEntryWrapper}>
            <img src={imgstep5} alt="step5" className={classes.helpImg} />
            <Typography>
              {`Select the API called "Auth0 Managment API" `}
              <b>select all permissions</b>, and click <b>Authorize</b>
            </Typography>
          </div>
          <div className={classes.helpEntryWrapper}>
            <img src={imgstep6} alt="step6" className={classes.helpImg} />
            <Typography>
              Copy the Domain, Client ID and Client Secret from Auth0 to this
              Cert Manager
            </Typography>
          </div>
        </div>
        <div className={classes.inputDiv}>
          <CustomInput
            labelText="???.eu.auth0.com"
            pattern={"."}
            formControlProps={{ fullWidth: true }}
            onChange={(value) => {
              setAuth0Domain(value);
            }}
            inputProps={{
              required: true,
            }}
          />
          <FormHelperText className={classes.helperText}>
            Paste the <b>Domain</b> from my Auth0 App
          </FormHelperText>
          <CustomInput
            labelText="Client ID"
            pattern={"."}
            formControlProps={{ fullWidth: true }}
            onChange={(value) => {
              setMgmgtClientId(value);
            }}
            inputProps={{
              required: true,
            }}
          />
          <FormHelperText className={classes.helperText}>
            Paste the <b>Client ID</b> from my Auth0 App
          </FormHelperText>
          <CustomInput
            labelText="Client Secret"
            pattern={"."}
            formControlProps={{ fullWidth: true }}
            onChange={(value) => {
              setMgmgtClientSecret(value);
            }}
            inputProps={{
              type: "password",
              required: true,
            }}
          />
          <FormHelperText>
            Paste the <b>Client Secret</b> from Auth0
          </FormHelperText>
        </div>

        <div>
          <Button onClick={back}>Back</Button>
          <Button type="submit" variant="contained" color="primary">
            FINISH
          </Button>
        </div>
      </form>
    </div>
  );
}

Step2.propTypes = {
  step: PropTypes.object,
  setStep: PropTypes.func,
  back: PropTypes.func,
};
