import React from "react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Link } from "react-router-dom";

import LayoutOneCard from "components/LayoutOneCard/LayoutOneCard";
import Button from "components/CustomButtons/Button.js";

export default function LandingPage() {
  return (
    <LayoutOneCard>
      <Card>
        <CardHeader color="primary">Welcome to Cert Manager</CardHeader>
        <CardBody>
          You are welcome to evaluate and use this Cert Manager. Please log in
          with your personal Google account.
          <Link to="/myinstances">
            <Button variant="contained" color="primary">
              Proceed to Login
            </Button>
          </Link>
        </CardBody>
      </Card>
    </LayoutOneCard>
  );
}
