import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function PauseIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M3,5V19L11,12M13,19H16V5H13M18,5V19H21V5" />
    </SvgIcon>
  );
}
