import { makeStyles } from "@material-ui/styles";

export default makeStyles(() => ({
  inputDiv: {
    width: 320,
    display: "flex",
    flexWrap: "wrap",
    margin: "0px",
  },
  helperText: {
    margin: "0px",
    padding: "0px",
  },
  countrySelect: {
    width: "250px",
    margin: "15px 0 0 0",
  },
  countryEntry: {
    fontSize: "70%",
  },
  switchLabel: {
    margin: "5px 0 0 0",
  },
  helpSectionContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    alignContent: "flex-start",
    padding: "10px",
  },
  helpEntryWrapper: {
    paddingBottom: 20,
    width: "90%",
    display: "flex",
    flexWrap: "wrap-reverse",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    alignContent: "flex-start",
  },
  helpImg: {
    flexBasis: "30%",
    margin: 0,
    maxWidth: "250px",
    boxShadow: "5px 5px 10px rgba(0,0,0,0.3)",
    "&:hover": {
      transition: "transform 1s",
      transform: "scale(1.5)",
    },
  },
  helpText: {
    flexBasis: "60%",
    margin: "20px",
  },
}));
