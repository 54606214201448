import { makeStyles } from "@material-ui/styles";

export default makeStyles(() => ({
  multilineTextField: {
    margin: "27px 0 0 0",
  },
  tiny: {
    fontSize: "60%",
    lineHeight: "100%",
  },
  padBelow: {
    paddingBottom: "30px",
  },
  additionalInfoBox: {
    width: "80%",
    padding: "20px",
    margin: "30px",
  },
  numberedList: {
    counterReset: "li",
    listStyleType: "none",
    fontSize: "14px",
    lineHeight: "18px",
    paddingLeft: "10px",
    li: {
      position: "relative",
      padding: "5px 0 5px 30px",
      "&::before": {
        content: "counter(li)",
        counterIncrement: "li",
        height: "20px",
        width: "20px",
        border: "1px solid blue",
        borderRadius: "50%",
        color: "red",
        textAlign: "center",
        position: "absolute",
        left: "0",
        top: "4px",
      },
    },
  },
}));
