import Android from "@material-ui/icons/Android";
import DashboardPage from "views/Dashboard/Dashboard.js";
import Settings from "views/Settings/Settings.js";
import Admin from "views/Admin/Admin.js";
import DeviceList from "views/CertificateLists/DeviceList";
import Users from "views/CertificateLists/Users";
import PartnerList from "views/CertificateLists/partner/PartnerList";
import MenuBookOutlined from "@material-ui/icons/MenuBookOutlined";
import DeviceIcon from "icons/DeviceIcon";

import EventLogIcon from "icons/EventLogIcon";
import VerifiableAuditLog from "views/AuditLog/VerifiableAuditLog";
const states = {
  ACTIVE: "active_certificate",
  ALL: "All",
};
const roles = {
  ADMIN: "Admin",
  ALL: "All",
};

const dashboardRoutes = [
  {
    state: states.ALL,
    role: roles.ALL,
    path: "/intro",
    name: "Intro",
    icon: MenuBookOutlined,
    component: DashboardPage,
  },
  {
    state: states.ACTIVE,
    role: roles.ALL,
    path: "/devices",
    name: "Devices",
    icon: DeviceIcon /** M20.2,5.9L21,5.1C19.6,3.7 17.8,3 16,3C14.2,3 12.4,3.7 11,5.1L11.8,5.9C13,4.8 14.5,4.2 16,4.2C17.5,4.2 19,4.8 20.2,5.9M19.3,6.7C18.4,5.8 17.2,5.3 16,5.3C14.8,5.3 13.6,5.8 12.7,6.7L13.5,7.5C14.2,6.8 15.1,6.5 16,6.5C16.9,6.5 17.8,6.8 18.5,7.5L19.3,6.7M19,13H17V9H15V13H5A2,2 0 0,0 3,15V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V15A2,2 0 0,0 19,13M8,18H6V16H8V18M11.5,18H9.5V16H11.5V18M15,18H13V16H15V18Z */,
    component: DeviceList,
  },
  {
    state: states.ACTIVE,
    role: roles.ALL,
    path: "/apps",
    name: "Users",
    icon: "assignment_ind",
    component: Users,
  },
  {
    state: states.ACTIVE,
    role: roles.ALL,
    path: "/subordinated-ca-certs",
    name: "Partners",
    icon: "business",
    component: PartnerList,
  },
  {
    state: states.ALL,
    role: roles.ALL,
    path: "/settings",
    name: "Settings",
    icon: "settings",
    component: Settings,
  },
  {
    state: states.ACTIVE,
    role: roles.ALL,
    path: "/event-log",
    name: "Verifiable Logs",
    icon: EventLogIcon,
    component: VerifiableAuditLog,
  },
  {
    state: states.ACTIVE,
    role: roles.ADMIN,
    path: "/admin",
    name: "Admin",
    icon: "admin_panel_settings",
    component: Admin,
  },
  {
    state: states.ALL,
    role: roles.ALL,
    path: "/app-release.apk",
    name: "Get a demo app",
    icon: Android,
  },
];

export default dashboardRoutes;
export { states, roles };
