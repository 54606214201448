import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import useStyles from "./styles";
import useCardStyles from "assets/jss/cert-manager/components/cardStyle";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import useApiParams from "services/backend/useApiParams";
import createInstance from "services/backend/createInstance";
import { config } from "Constants";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Typography from "@material-ui/core/Typography";
import Step0 from "./steps/Step0";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Button from "components/CustomButtons/Button.js";

function getSteps() {
  return [
    "Describe my CA",
    "Get an Auth0 environment for authenticating my users",
    "Connect my Cert Manager with my new Auth0 environment",
  ];
}
function getStepContent(step) {
  switch (step) {
    case 0:
      return `Enter Basepath + Details for my own cert. My cert will be signed later.`;
    case 1:
      return "Open https://www.auth0.com in another browser window. Login and create a new environment (tenant) from the topright menu";
    case 2:
      return `Copy the Domain, Client ID and Client Secret from my Auth0 App to this Cert Manager. The Cert Manager will configure my Auth0 environment. `;
    default:
      return "Unknown step";
  }
}

export default function NewInstanceDialog(props) {
  const cardClasses = useCardStyles();
  const componentClasses = useStyles();
  const classes = { ...cardClasses, ...componentClasses };

  const { onClose, open } = props;

  const { params: apiParams } = useApiParams();

  const [step0, setStep0] = useState();
  const [step2, setStep2] = useState();

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (step2) {
      handleFinish();
    }
  }, [step2]);

  const handleFinish = () => {
    const basepath = step0.basepath;
    const countryCode = step0.country.code;
    const organizationName = step0.organizationName;
    const commonName = step0.commonName;
    const demo = step0.demo;
    const mgmtClient = { ...step2 };
    console.log("Finish ", basepath);
    createInstance(
      apiParams,
      basepath,
      countryCode,
      organizationName,
      commonName,
      demo,
      mgmtClient
    )
      .then((res) => {
        if (res.error) {
          console.log("error", res.msg);
        } else {
          onClose();
          window.location.href = `${config.url.FRONTEND}${
            basepath.charAt(0) === "/" ? basepath : `/${basepath}`
          }`;
        }
      })
      .catch((err) => {
        console.log("CreateInstance error", err);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      className={classes.dialog}
      fullScreen={true}
    >
      <DialogContent>
        <Card>
          <CardHeader color="primary">
            <h4>Create my CertManager and become a Cert Authority</h4>
            <p>{`
              In just a few steps...
              `}</p>
          </CardHeader>
          <CardBody>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                  <StepContent>
                    <Typography className={classes.stepperDescr}>
                      {getStepContent(index)}
                    </Typography>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            {activeStep === 0 ? (
              <Step0 step={step0} setStep={setStep0} next={handleNext} />
            ) : null}
            {activeStep === 1 ? (
              <Step1 next={handleNext} back={handleBack} />
            ) : null}
            {activeStep === 2 ? (
              <Step2 back={handleBack} step={step2} setStep={setStep2} />
            ) : null}
          </CardBody>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

NewInstanceDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
