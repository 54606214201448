import React, { createRef, useEffect, useState, useMemo } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import useInstanceStatus from "services/backend/useInstanceStatus";
import { CMContext } from "services/backend/CMContext";

import routes, { roles } from "routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/companylogo.png";

const color = "blue";
const logoText = "Cert Manager";

let ps;

const useStyles = makeStyles(styles);

export default function Main({ ...rest }) {
  const {
    match: { url },
  } = rest;

  const {
    instance,
    update: instanceUpdate,
    error: instanceError,
  } = useInstanceStatus();

  //Add global states to this object
  const cm = {
    instance: { instance, update: useMemo(() => instanceUpdate) },
  };

  useEffect(() => {
    if (instanceError) {
      console.error("useInstanceStatus error", instanceError);
    }
  }, [instanceError]);

  const switchRoutes = (routes) => (
    <Switch>
      {routes.map((route, key) => (
        <Route path={url + route.path} component={route.component} key={key} />
      ))}
      <Redirect from={url} to={`${url}/intro`} />
    </Switch>
  );

  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = createRef();

  const { user } = useAuth0();

  const [mobileOpen, setMobileOpen] = useState(false);
  const [filteredRoutes, setFilteredRoutes] = useState([]);

  useEffect(() => {
    const array = [];
    routes.forEach((route) => {
      switch (route.role) {
        case roles.ALL:
          array.push(route);
          break;
        case roles.ADMIN:
          if (user["https://ca.controlthings.fi/roles"].includes(roles.ADMIN)) {
            array.push(route);
          }
          break;
      }
    });
    setFilteredRoutes(array);
  }, [routes]);

  // initialize and destroy the PerfectScrollbar plugin
  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  return (
    <div className={classes.wrapper}>
      <CMContext.Provider value={cm}>
        <Sidebar
          url={url}
          routes={filteredRoutes}
          logoText={logoText}
          logo={logo}
          image={bgImage}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color={color}
          {...rest}
        />
        <div className={classes.mainPanel} ref={mainPanel}>
          <Navbar
            url={url}
            routes={filteredRoutes}
            handleDrawerToggle={handleDrawerToggle}
            {...rest}
          />
          <div className={classes.content}>
            <div className={classes.container}>
              {switchRoutes(filteredRoutes)}
            </div>
          </div>
          <Footer />
        </div>
      </CMContext.Provider>
    </div>
  );
}
