import React from "react";
import PropTypes from "prop-types";
import useStyles from "../styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Button from "components/CustomButtons/Button.js";
import useApiParams from "services/backend/useApiParams";
import email from "services/backend/email";
import { useAuth0 } from "@auth0/auth0-react";
import CustomDropzone from "./components/CustomDropzone/CustomDropzone";
import DialogActions from "@material-ui/core/DialogActions";

export default function SendRequestToExternalCaDialog(props) {
  const classes = useStyles();
  const { open, onClose, updateStatus } = props;

  const { params: apiParams } = useApiParams();
  const { user } = useAuth0();

  const handleSendEmail = () => {
    email(apiParams, user.email)
      .then((res) => {
        console.log("email responded", res);
      })
      .catch((error) => {
        console.log("email error", error);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ className: classes.dialog }}
    >
      <DialogContent>
        <Typography variant="h5">
          You need to request an updated Cert before it expires
        </Typography>
        <Typography variant="subtitle1">
          the CSR and instructions will be sent to your email
        </Typography>
        <Typography variant="body1">
          The CertManager will begin to remind you by email, when 50% of the
          validity period has elapsed. The email contains a request that you
          need to forward to your parent certificate authority, who has issued
          your CertManager cert.
        </Typography>
        <br />
        <Typography variant="h6">When you receive the files</Typography>
        <Typography variant="body1">
          Upload the received cert files (CertManager cert, Root Cert and chain
          to root) from your cert authority, to the dropzone below
        </Typography>
        <CustomDropzone updateStatus={updateStatus} onClose={onClose} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() =>
            window.open(
              "https://www.youtube.com/watch_popup?v=L1LfZAVhpr8",
              "newWindow",
              "width=640,height=360"
            )
          }
          color="primary"
        >
          Watch Tutorial
        </Button>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSendEmail} color="primary">
          Send me the email
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SendRequestToExternalCaDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  updateStatus: PropTypes.func,
};
