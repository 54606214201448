import React, { useState } from "react";
import PropTypes from "prop-types";

import useStyles from "./styles";
import useCardStyles from "assets/jss/cert-manager/components/cardStyle";

import { config } from "Constants";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";

//Icons
import IconButton from "@material-ui/core/IconButton";
import BusinessIcon from "@material-ui/icons/Business";
import HelpIcon from "icons/HelpIcon";
import AccessTime from "@material-ui/icons/AccessTime";

export default function Partner(props) {
  const cardClasses = useCardStyles();
  const componentClasses = useStyles();
  const classes = { ...cardClasses, ...componentClasses };

  const { basepath } = props;

  const url = config.url.BACKEND;

  const [server, setServer] = useState(true);
  const [client, setClient] = useState(true);

  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>
          <BusinessIcon /> Partner certification
        </h4>
        <p className={classes.cardCategoryWhite}>
          Your subordinated service partners are manually invited, but their
          cert renewal is automated
        </p>
      </CardHeader>
      <CardBody>
        <TextField
          label="Cert rotation"
          style={{ margin: 10 }}
          placeholder={`${url}${basepath}/rotateCert`}
          helperText="Partner instances of Cert Manager are automatically renewing their CA Cert using this URL (which first is authenticating using their old cert)"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Tooltip title="On certificate rotation, an exactly similar certificate as the previous one is issued, only the validity period differs.">
          <IconButton>
            <HelpIcon />
          </IconButton>
        </Tooltip>
        {process.env.NODE_ENV === "development" ? (
          <>
            <br />
            <TextField
              label="Max depth of further SubCAs"
              type="number"
              margin="normal"
              defaultValue="0"
              style={{ margin: 10 }}
              disabled={true}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="Validity period (days)"
              type="number"
              margin="normal"
              defaultValue="1095"
              style={{ margin: 10 }}
              disabled={true}
              InputLabelProps={{
                startadornment: (
                  <InputAdornment position="start">
                    <AccessTime />
                  </InputAdornment>
                ),
                shrink: true,
              }}
            />
            <br />
            <FormControl
              component="fieldset"
              margin="normal"
              style={{ margin: 10 }}
            >
              <FormHelperText>
                Partners can issue certs with following capabilities
              </FormHelperText>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={server}
                      onChange={() => setServer(!server)}
                      disabled={true}
                      name="server"
                    />
                  }
                  label="TLS server"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={client}
                      onChange={() => setClient(!client)}
                      disabled={true}
                      name="client"
                    />
                  }
                  label="TLS client"
                />
              </FormGroup>
            </FormControl>
          </>
        ) : null}
      </CardBody>
      {/** <CardFooter chart>
        <div className={classes.stats}>
          <AccessTime /> 4 issued certificates
        </div>
      </CardFooter> */}
    </Card>
  );
}

Partner.propTypes = {
  basepath: PropTypes.string,
};
