import axios from "axios";
import { config } from "Constants";

export default function getApi(params) {
  if (params) {
    const { token, path } = params;
    return axios.create({
      baseURL: `${config.url.BACKEND}${path}`,
      headers: { Authorization: `Bearer ${token}` },
    });
  } else {
    const path = "/public";
    return axios.create({
      baseURL: `${config.url.BACKEND}${path}`,
    });
  }
}
