import React, { useState } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";

import Tooltip from "@material-ui/core/Tooltip";
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Icon from '@material-ui/core/Icon';

export default function DropdownButton(props) {

  const { icon, options, maxHeight, width, tooltip } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (item) => (event) => {
    item.click();
    handleClose();
}

  return (
    <div>
      <Tooltip title={tooltip}>
        <IconButton
          aria-controls="dropdown-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <Icon>{icon}</Icon>
        </IconButton>
      </Tooltip>
      <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: maxHeight * 4.5,
            width: width ? `${width}ch` : undefined,
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.name} onClick={handleMenuClick(option)}>
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

DropdownButton.defaultProps = {
  icon: "more_horiz",
  maxHeight: 48,
  options: [],
  tooltip: "Edit"
};

DropdownButton.propTypes = {
  icon: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, click: PropTypes.func })),
  maxHeight: PropTypes.number,
  width: PropTypes.number,
  tooltip: PropTypes.string

};
