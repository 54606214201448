import React from "react";
import PropTypes from "prop-types";

import useStyles from "./styles";
import useCardStyles from "assets/jss/cert-manager/components/cardStyle";

import { config } from "Constants";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";

//Icons
import HighlightOffOutlined from "@material-ui/icons/HighlightOffOutlined";
import HelpIcon from "icons/HelpIcon";

export default function Revocation(props) {
  const cardClasses = useCardStyles();
  const componentClasses = useStyles();
  const classes = { ...cardClasses, ...componentClasses };

  const { basepath } = props;

  const url = config.url.BACKEND;

  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>
          <HighlightOffOutlined /> Certificate revocation
        </h4>
        <p className={classes.cardCategoryWhite}></p>
      </CardHeader>
      <CardBody>
        <h5>Revocation status mechanisms</h5>
        <small>
          Every issued cert is pointing to these for the cert validators to
          find. To revoke a certificate: look it up from the registry, and use
          the revoke feature.
          <br />
        </small>
        CRL - Certificate Revocation List
        <Tooltip title="The blacklist of invalidated certificates. The file is timestamped and signed by this CA, and can thereby be forwarded for offline certificate validation. Frequent revocation causes this file to swell (consider to prefer short validity period of certificates).">
          <IconButton>
            <HelpIcon />
          </IconButton>
        </Tooltip>
        <TextField
          label="Distribution point for the tamper protected blacklist (CRL)"
          style={{ margin: 10 }}
          placeholder={`${url}${basepath}/public/CRL`}
          fullWidth
          disabled={true}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        {process.env.NODE_ENV === "development" ? (
          <>
            <Divider />
            OCSP - Online Certificate Status Protocol
            <Tooltip title="An online API for checking the status of a specified certificate, without having to download the list of all revoked certificates.">
              <IconButton>
                <HelpIcon />
              </IconButton>
            </Tooltip>
            <TextField
              label="URL to the Status Protocol"
              style={{ margin: 10 }}
              placeholder={`${url}${basepath}/public/OCSP`}
              fullWidth
              disabled={true}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </>
        ) : null}
      </CardBody>
    </Card>
  );
}

Revocation.propTypes = {
  basepath: PropTypes.string,
};
