import React, { useState } from "react";
import PropTypes from "prop-types";
import useStyles from "../../../../layouts/styles";
import useCardStyles from "assets/jss/cert-manager/components/cardStyle";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "components/CustomButtons/Button.js";

import Typography from "@material-ui/core/Typography";
import Step0 from "./steps/Step0";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
//import { DropzoneArea } from "material-ui-dropzone";

function getSteps() {
  return [
    "Import CSR",
    "Review the content",
    `${
      process.env.NODE_ENV === "development"
        ? "Adjust permissions and sign"
        : "Sign"
    } `,
  ];
}
function getStepContent(step) {
  switch (step) {
    case 0:
      return `Paste the CSR, or drag file`;
    case 1:
      return "Does it look correct?";
    case 2:
      return process.env.NODE_ENV === "development"
        ? `Set the length, max depth and permissions then sign`
        : "";
    default:
      return "Unknown step";
  }
}

export default function CSRDialog(props) {
  const cardClasses = useCardStyles();
  const componentClasses = useStyles();
  const classes = { ...cardClasses, ...componentClasses };

  const { onClose, open } = props;

  const [step0, setStep0] = useState();
  const [step2, setStep2] = useState();

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullScreen={true}
    >
      <DialogContent>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>
              Import a received cert request (CSR)
            </h4>
            <p className={classes.cardCategoryWhite}>{`
              Certify only organisations you trust as subordinated partners
              `}</p>
          </CardHeader>
          <CardBody>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                  <StepContent>
                    <Typography className={classes.stepperDescr}>
                      {getStepContent(index)}
                    </Typography>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            {activeStep === 0 ? (
              <Step0 setStep={setStep0} next={handleNext} step0={step0} />
            ) : null}
            {activeStep === 1 ? (
              <Step1 step0={step0} next={handleNext} back={handleBack} />
            ) : null}
            {activeStep === 2 ? (
              <Step2
                step={step2}
                setStep={setStep2}
                next={handleNext}
                back={handleBack}
                step0={step0}
              />
            ) : null}
            {activeStep === 3 ? (
              <Step3 next={onClose} back={handleBack} step2={step2} />
            ) : null}
          </CardBody>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CSRDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
