import { useState, useEffect, useCallback } from "react";
import getApi from "./api";
import useApiParams from "services/backend/useApiParams";

export default function useMyInstances(activeOnly) {
  const { params } = useApiParams();
  const [instances, setInstances] = useState([]);
  const [error, setError] = useState(false);

  const update = useCallback(() => {
    if (params) {
      getMyInstances(params, activeOnly)
        .then((res) => {
          setInstances(res);
        })
        .catch((err) => {
          setError(err);
        });
    }
  }, [params]);

  useEffect(() => {
    update();
  }, [update]);

  return { instances, update, error };
}

export function getMyInstances(apiParams, activeOnly) {
  const api = getApi(apiParams);
  return new Promise((resolve, reject) => {
    api
      .post("/getMyInstances", { activeOnly })
      .then((res) => {
        if (res.error) {
          reject(res.error);
        }
        if (res.data.error) {
          reject(res.data.error);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
