import React, { useEffect, useState, useContext } from "react";

import { CMContext } from "services/backend/CMContext";
import ActiveCertificate from "./activeCertificate/ActiveCertificate";
import ConfigurationUncomplete from "./configurationUncomplete/ConfigurationUncomplete";

export default function Settings() {
  const [status, setStatus] = useState();

  const global = useContext(CMContext);
  const { instance, update } = global.instance;

  useEffect(() => {
    if (instance.status) {
      setStatus(instance.status);
    }
  }, [instance]);

  return (
    <>
      {status === "active_certificate" ? (
        <ActiveCertificate updateStatus={update} {...instance} />
      ) : null}
      {status === "configuration_uncomplete" ? (
        <ConfigurationUncomplete updateStatus={update} {...instance} />
      ) : null}
    </>
  );
}
