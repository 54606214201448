import getApi from "./api";

export default function storeReceivedCert(
  apiParams,
  myCertPEM,
  rootPEM,
  myChainToRootPEM,
  rootCrlPEM,
  hasOfflineRoot
) {
  const api = getApi(apiParams);
  return new Promise((resolve, reject) => {
    api
      .post("/receiveAndStoreCertBundle", {
        myCertPEM,
        rootPEM,
        myChainToRootPEM,
        rootCrlPEM,
        hasOfflineRoot,
      })
      .then((res) => {
        if (res.error) {
          reject(res.error);
        }
        if (res.data.error) {
          reject(res.data.error);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
