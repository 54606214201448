import getApi from "./api";

export default function selfSign(apiParams, csr) {
  const api = getApi(apiParams);
  return new Promise((resolve, reject) => {
    api
      .post("/selfSign", {
        csr,
      })
      .then((res) => {
        if (res.error) {
          reject(res.error);
        }
        if (res.data.error) {
          reject(res.data.error);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
