import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import useStyles from "./styles";

import { parse } from "components/PEMParser/PEMParser";

export default function Preview({
  fileWithMeta: file,
  setRootCRL,
  setCertManagerCert,
  setOfflineRootCert,
}) {
  const classes = useStyles();

  const { name, status } = file.meta;

  const [type, setType] = useState(true);
  const [explanation, setExplanation] = useState("");

  useEffect(() => {
    if (file.file) {
      const reader = new FileReader();
      reader.onabort = () => {
        setExplanation("file reading was aborted");
        setType(false);
      };
      reader.onerror = () => {
        setExplanation("file reading has failed");
        setType(false);
      };
      reader.onload = () => {
        const str = reader.result;
        const parsedFile = parse(str);
        if (parsedFile === undefined) {
          setExplanation(
            "Unrecognized file, please remove it and use PEM files instead"
          );
          setType(false);
          return;
        }
        switch (parsedFile.type) {
          case "CERTIFICATE":
            if (
              parsedFile.data[0].issuer.distinguishedName ===
              parsedFile.data[0].subject.distinguishedName
            ) {
              setOfflineRootCert(str);
              setType("Regognized as ROOT cert:");
            } else {
              setCertManagerCert(str);
              setType("Recognized as CertManager cert:");
            }
            break;
          case "CRL":
            setType("Regognized as CRL:");
            setRootCRL(str);
            //CRL.current = str;
            break;
          default:
            setExplanation(
              "Unrecognized file, please remove it and use PEM files instead"
            );
            setType(false);
        }
      };
      try {
        reader.readAsText(file.file);
      } catch (error) {
        setExplanation("Not able to read file");
        setType(false);
      }
    }
  }, []);

  return (
    <span className={type ? classes.normal : classes.unsupported}>
      {`${type ? type : explanation} ${name} `}
      <button
        onClick={() => {
          file.remove();
        }}
      >
        remove
      </button>
    </span>
  );
}

Preview.propTypes = {
  fileWithMeta: PropTypes.object,
  setRootCRL: PropTypes.func,
  setCertManagerCert: PropTypes.func,
  setOfflineRootCert: PropTypes.func,
};
