import React from "react";
import PropTypes from "prop-types";
import useStyles from "./styles";
import useCardStyles from "assets/jss/cert-manager/components/cardStyle";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "components/CustomButtons/Button.js";

export default function Step3(props) {
  const cardClasses = useCardStyles();
  const componentClasses = useStyles();
  const classes = { ...cardClasses, ...componentClasses };

  const { next, back, step2 } = props;

  const { issuedCertPEM, chainBundlePEM, rootPEM } = step2;

  const onSubmit = (event) => {
    event.preventDefault();
    next();
  };

  return (
    <div className={classes.inputDiv}>
      <form onSubmit={onSubmit}>
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography className={classes.stepperDescr}>
            You have issued a new certificate. Please send the following cert
            and bundle to your partner who requested it.
          </Typography>
          <Typography className={classes.stepperDescr}>
            The new issued cert
          </Typography>
          <pre>{issuedCertPEM}</pre>
          <Typography className={classes.stepperDescr}>
            The chain of certs to root
          </Typography>
          <pre>{chainBundlePEM}</pre>
          <Typography className={classes.stepperDescr}>
            The root cert
          </Typography>
          <pre>{rootPEM}</pre>
        </Paper>

        <div>
          <Button onClick={back}>Back</Button>
          <Button type="submit" variant="contained" color="primary">
            Close
          </Button>
        </div>
      </form>
    </div>
  );
}

Step3.propTypes = {
  next: PropTypes.func,
  back: PropTypes.func,
  step2: PropTypes.object,
};
