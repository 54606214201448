import { useState, useEffect, useCallback } from "react";
import getApi from "./api";
import useApiParams from "services/backend/useApiParams";

export default function useCAConfig() {
  const { params } = useApiParams();
  const [config, setConfig] = useState([]);
  const [error, setError] = useState(false);
  const [confirmSave, setConfirmSave] = useState(false);

  const update = useCallback(() => {
    if (params) {
      setConfirmSave(false);
      getCAConfig(params)
        .then((res) => {
          setConfig(res);
        })
        .catch((err) => {
          setError(err);
        });
    }
  }, [params]);

  useEffect(() => {
    update();
  }, [update, params]);

  const save = (config) => {
    if (params) {
      saveCAConfig(params, config)
        .then(() => {
          setConfirmSave(true);
        })
        .catch((err) => {
          setError(err);
        });
    }
  };

  useEffect(() => {
    if (confirmSave) {
      update();
    }
  }, [confirmSave]);

  return { config, update, save, error };
}

export function saveCAConfig(apiParams, config) {
  const api = getApi(apiParams);
  return new Promise((resolve, reject) => {
    api
      .post("/saveCAConfig", { config: config.toString() })
      .then((res) => {
        if (res.error) {
          reject(res.error);
        }
        if (res.data.error) {
          reject(res.data.error);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getCAConfig(apiParams) {
  const api = getApi(apiParams);
  return new Promise((resolve, reject) => {
    api
      .get("/getCAConfig")
      .then((res) => {
        if (res.error) {
          reject(res.error);
        }
        if (res.data.error) {
          reject(res.data.error);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
