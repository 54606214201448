import { makeStyles } from "@material-ui/styles";
import {
  dangerColor,
  successColor,
  grayColor,
} from "assets/jss/material-dashboard-react.js";

export default makeStyles(() => ({
  cert: {
    width: "100 %",
  },
  statusValid: {
    flexBasis: "20%",
    color: successColor[0],
  },
  statusExpired: {
    flexBasis: "20%",
    color: grayColor[0],
  },
  statusSuperseded: {
    flexBasis: "20%",
    color: grayColor[0],
  },
  statusRevoked: {
    flexBasis: "20%",
    color: dangerColor[0],
  },
  paperHeadingInactive: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    color: grayColor[0],
    fontSize: "80%",
    display: "inline",
    flexBasis: "50%",
    margin: `0 5 0 5 px`,
  },
  paperHeading: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    color: "Black",
    fontSize: "80%",
    display: "inline",
    flexBasis: "50%",
    margin: `0 5 0 5 px`,
  },
  paperDateInactive: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    color: grayColor[0],
    fontSize: "80%",
    display: "inline",
    flexBasis: "10%",
    margin: `0 5 0 5 px`,
  },
  paperDate: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    color: "Black",
    fontSize: "80%",
    display: "inline",
    flexBasis: "10%",
    margin: `0 5 0 5 px`,
  },
  paperText: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    color: "Gray",
    fontSize: "90%",
    display: "inline",
  },
  videoContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
    alignItems: "flex-start",
  },
  videoDialog: {
    width: "100%",
  },
  reactPlayer: {
    width: "1000px",
    height: "100px",
  },
}));
