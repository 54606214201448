import React from "react";
import PropTypes from "prop-types";
import useStyles from "./styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Button from "components/CustomButtons/Button.js";
import { DialogActions } from "@material-ui/core";

export default function RotateSelfSignedOnlineRootCertDialog(props) {
  const classes = useStyles();

  const { open, onClose, onTriggerRotate } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ className: classes.dialog }}
    >
      <DialogContent>
        <Typography variant="h5">Rotation of the Online Root Cert</Typography>
        <Typography variant="subtitle1">
          will automatically be executed before it expires
        </Typography>
        <Typography variant="body2">
          When 50% of the issued CertManager cert validity period have elapsed,
          the CertManager will renew the certificate automatically behind the
          scenes.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onTriggerRotate} color="primary">Rotate NOW</Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

RotateSelfSignedOnlineRootCertDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onTriggerRotate: PropTypes.func,
};
