import React, { useEffect, useState, useContext } from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import useDeviceCerts from "services/backend/useDeviceCerts";
import CertificateTable from "./components/CertificateTable";
import useStyles from "./styles";
import DeviceIcon from "icons/DeviceIcon";

import Fab from "components/CustomButtons/Fab";
import AddIcon from "@material-ui/icons/Add";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Button from "components/CustomButtons/Button.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { removeFromExtension } from "./components/Parser";
import VideoDialog from "./components/VideoDialog";
import deviceCertSequenceImage from "assets/img/device-certification-sequence.png";
import DevTutorialDialog from "components/Dialog/DevTutorialDialog";
import { CMContext } from "services/backend/CMContext";
import useClientSecret from "services/backend/useClientSecret";
import { filter } from "jszip";

export default function Devices() {
  const classes = useStyles();
  const [openDevTutorialDialog, setOpenDevTutorialDialog] = useState(false);
  const global = useContext(CMContext);
  const { instance } = global.instance;
  const { id, secret } = useClientSecret();
  const { basepath, auth0domain } = instance;

  const columns = [
    { title: "Device", field: "commonName" },
    { title: "Phase", field: "regPhase" },
    { title: "Customer", field: "regCustomer" },
    { title: "Dealer", field: "regDealer" },
    { title: "Certs", field: "certCount" },
    { title: "Last cert expires", field: "expire" },
  ];

  const [dialogOpen, setDialogOpen] = useState(false);
  const [videoDialogOpen, setVideoDialogOpen] = useState(false);

  const {
    certs: deviceCerts,
    update: updateDeviceCerts,
    error: deviceError,
  } = useDeviceCerts();

  const [certs, setCerts] = useState({});
  const [filter, setFilter] = useState([]);

  useEffect(() => {
    if (deviceError) {
      console.log("device error", deviceError);
    }
  }, [deviceError]);

  useEffect(() => {
    const subjectCerts = {};
    deviceCerts.forEach((cert) => {
      let currentCert = subjectCerts[cert.subject_common_name];
      if (!currentCert) {
        // no such cert found, initiate an element
        currentCert = { inAllCerts: {}, certs: [], extra: {} };
        currentCert.inAllCerts.totalCount = 0;
        currentCert.inAllCerts.validCount = 0;
      }
      if (!currentCert.extra.regPhase) {
        currentCert.extra.regPhase = removeFromExtension(
          cert.extensions["1.2.246.10.23378962.10.2"]
        );
      } else {
        if (
          cert.extensions["1.2.246.10.23378962.10.2"] &&
          cert.extensions["1.2.246.10.23378962.10.2"].search("Bootstrap") < 0
        ) {
          currentCert.extra.regPhase = removeFromExtension(
            cert.extensions["1.2.246.10.23378962.10.2"]
          );
        }
      }
      if (!currentCert.extra.regCustomer) {
        currentCert.extra.regCustomer = removeFromExtension(
          cert.extensions["1.2.246.10.23378962.10.4"]
        );
      } else {
        if (cert.extensions["1.2.246.10.23378962.10.4"]) {
          currentCert.extra.regCustomer = removeFromExtension(
            cert.extensions["1.2.246.10.23378962.10.4"]
          );
        }
      }
      if (!currentCert.extra.regDealer) {
        currentCert.extra.regDealer = removeFromExtension(
          cert.extensions["1.2.246.10.23378962.10.3"]
        );
      } else {
        if (cert.extensions["1.2.246.10.23378962.10.3"]) {
          currentCert.extra.regDealer = removeFromExtension(
            cert.extensions["1.2.246.10.23378962.10.3"]
          );
        }
      }
      currentCert.inAllCerts.commonName = cert.subject_common_name;
      currentCert.inAllCerts.totalCount++;
      if (!cert.expired && cert.status === "V") {
        currentCert.inAllCerts.validCount++;
      }
      if (
        !currentCert.inAllCerts.maxExpire ||
        currentCert.inAllCerts.maxExpire < cert.not_after
      ) {
        currentCert.inAllCerts.maxExpire = cert.not_after;
      }
      if (cert.not_before)
        if (
          !currentCert.inAllCerts.mostRecent ||
          currentCert.inAllCerts.mostRecent < cert.not_before
        ) {
          currentCert.inAllCerts.mostRecent = cert.not_before;
        }
      currentCert.certs.push(cert);
      subjectCerts[cert.subject_common_name] = currentCert;
    });
    setCerts(subjectCerts);
  }, [deviceCerts]);

  const handleClickDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const handleClickCheckbox = (event, value) => {
    const { id } = event.target;
    let _filter;
    if (value) {
      _filter = [...filter];
      _filter.push(id);
    } else {
      _filter = filter.filter((item) => {
        return item != id;
      });
    }
    setFilter(_filter);
  };

  return (
    <GridContainer>
      <GridItem>
        {process.env.NODE_ENV === "development" ? (
          <Fab
            variant="extended"
            color="primary"
            onClick={handleClickDialogOpen}
          >
            <AddIcon />
            Import bootstr. cert batch
          </Fab>
        ) : null}
        <Fab
          variant="extended"
          color="primary"
          onClick={() => setVideoDialogOpen(true)}
        >
          ?
        </Fab>
        <Fab
          style={{ margin: 10 }}
          variant="extended"
          onClick={() => setOpenDevTutorialDialog(true)}
        >
          Device Developer Tutorial
        </Fab>
        <DevTutorialDialog
          open={openDevTutorialDialog}
          onClose={() => setOpenDevTutorialDialog(false)}
          clientId={id}
          clientSecret={secret}
          basepath={basepath}
          auth0domain={auth0domain}
          className={classes.Dialog}
          fullWidth={true}
          maxWidth={false}
        />
      </GridItem>
      <GridItem>
        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Bulk import of bootstrap certs issued by manufacturer (not yet
            implemented)
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {`Import a .csv file containing a list of bootstrap certificates
              issued by your manufacturer. It's added to a whitelist, to allow
              the devices to register and replace the bootstrap certificate with
              the final credential at the first use.`}
              <p>
                The device registration can prompt the commissioner for details
                (such as distributor, purchase date, customer name, etc.) or it
                can be a completely automatic Just-In-Time-Registration. <br />
              </p>
              <Button size="sm" variant="outlined" color="primary">
                Select .CSV file
              </Button>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleDialogClose}
              variant="contained"
              disabled
              color="primary"
            >
              Upload and Import
            </Button>
          </DialogActions>
        </Dialog>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>
              <DeviceIcon />
              Certified Devices
            </h4>
            <p className={classes.cardCategoryWhite}>
              Some of the devices in the list may be in stock and not
              commissioned. Use filters to find what you are looking for.
            </p>
          </CardHeader>
          <CardBody>
            <VideoDialog
              title="Device certification process (example)"
              url="https://youtu.be/9oXZYH4W1Ak"
              image={deviceCertSequenceImage}
              imageAlt="Certification sequence"
              open={videoDialogOpen}
              onClose={() => setVideoDialogOpen(false)}
            />
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    id="Bootstrap Unregistered"
                    onChange={handleClickCheckbox}
                  />
                }
                label="Not yet activated"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    id="Registered device"
                    onChange={handleClickCheckbox}
                  />
                }
                label="Activated"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    id="revoked"
                    onChange={handleClickCheckbox}
                  />
                }
                label="Revoked"
              />
            </FormGroup>
          </CardBody>
          <CardBody>
            <CertificateTable
              certs={certs}
              columns={columns}
              filter={filter}
              update={updateDeviceCerts}
              commonNameIcon={<DeviceIcon />}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
