import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import useStyles from "./styles";
import useApiParams from "services/backend/useApiParams";
import useCardStyles from "assets/jss/cert-manager/components/cardStyle";
import Button from "components/CustomButtons/Button.js";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import parseCSR from "services/backend/parseCSR";

export default function Step0(props) {
  const cardClasses = useCardStyles();
  const componentClasses = useStyles();
  const classes = { ...cardClasses, ...componentClasses };

  const { step, setStep, next } = props;

  const { params: apiParams } = useApiParams();

  const [csr, setCsr] = useState("");

  useEffect(() => {
    if (step) {
      setCsr(step.csr);
    }
  }, [step]);

  const onSubmit = (event) => {
    event.preventDefault();

    parseCSR(apiParams, csr)
      .then((result) => {
        const { textFormatCSR, signature, subject, pubkey } = result;
        const _step = { ...step };
        _step.csr = csr;
        _step.textFormatCSR = textFormatCSR;
        _step.signature = signature;
        _step.subject = subject;
        _step.pubkey = pubkey;

        setStep(_step);
        next();
      })
      .catch((error) => {
        console.log("got error", error);
      });
  };

  return (
    <div className={classes.inputDiv}>
      <form onSubmit={onSubmit}>
        <div className={classes.inputDiv}>
          <TextareaAutosize
            /*className={classes.confTextarea}*/
            value={csr}
            required
            onChange={(event) => setCsr(event.target.value)}
            aria-label="CSRPEM"
            rowsMin={10}
            placeholder="Paste a received CSR PEM"
            //defaultValue="CSR delivered by someone you know (in PEM format)"
            /*label="CSR delivered by someone you know (in PEM format)"*/
          />
          {/* 
                  <DropzoneDialog
                    open={this.state.open}
                    onSave={this.handleSave.bind(this)}
                    acceptedFiles={['CSR']}
                    showPreviews={true}
                    maxFileSize={5000000}
                    onClose={this.handleClose.bind(this)}
                />*/}
        </div>
        <div>
          <Button type="submit" variant="contained" color="primary">
            NEXT
          </Button>
        </div>
      </form>
    </div>
  );
}

Step0.propTypes = {
  step: PropTypes.object,
  setStep: PropTypes.func,
  next: PropTypes.func,
};
