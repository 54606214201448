import { makeStyles } from "@material-ui/styles";
import bgImage from "assets/img/sidebar-2.jpg";

export default makeStyles(() => ({
  contentWrapper: {
    padding: "10%",
    backgroundImage: `url(${bgImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));
