import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import Approved from "./Approved";

export default function PrivateRoute({ component, ...rest }) {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

  const { path } = rest;

  const options = {
    redirectUri: window.location.origin + path,
  };
  return (
    <>
      {!isLoading ? (
        <Route
          {...rest}
          render={(props) =>
            isAuthenticated ? (
              <Approved component={component} {...props} />
            ) : (
              loginWithRedirect(options)
            )
          }
        />
      ) : null}
    </>
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.any,
};
