import React from "react";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import LoggerIcon from "icons/LoggerIcon";


const styles = {
    eventCard: {
      marginTop: "20px",
      padding: "6px",
      fontSize: "14px",
      display: "flex",
      border: "1.5px solid orange",
      borderRadius: "6px",
    },
    linkToRawData: {
        fontSize: "10px",
    },
    triggerChip: {
        fontSize: "75%",    
    },
    loggerChip:{
        //fontSize: "75%",    
    },
    participants: {
        flexBasis: '20%',
        display: "inline",
    },
    verifiedAnchor: {
        transform: "rotate(90deg)",
        marginTop: "-5px",
        marginLeft: "60px",
        color: "orange",
    },
    eventData: {
        flexBasis: '70%',
        display: "inline",
        margin: "20 px",
        fontSize: "75%",    
        border: "1px dashed lightgray",
        
    }
};

const useStyles = makeStyles(styles);

export default function LogRecorder( props ) {
    const classes = useStyles();
    const {recorderName, loggerCert } = props;
    return (
        <> 
        <Paper elevation={4} className={classes.eventCard}>
            <div className={classes.participants}>
            <Tooltip title={`Logging system certificate ${loggerCert}`}><Chip className={classes.loggerChip}
                size="small"
                avatar={<LoggerIcon />}
                label={`${loggerCert}`}
                
            /></Tooltip> 
            </div>
            <div>Tamper resistant event log, recorded by <b>{recorderName}</b><br /><small>The following events have been triggered by external users and devices</small></div>
        </Paper>
        </>
    )
}