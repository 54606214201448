import React, { useState } from "react";
import PropTypes from "prop-types";

import useStyles from "./styles";
import useCardStyles from "assets/jss/cert-manager/components/cardStyle";
import moment from "moment";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import useApiParams from "services/backend/useApiParams";
import { getCertPEM } from "services/backend/useCertPEM";
import Identicon from "react-identicons";
//Icons
import CertificateIcon from "icons/CertificateIcon";
import ExpandMoreIcon from "@material-ui/icons/ExpandMoreOutlined";
import CertificateRootIcon from "icons/CertificateRootIcon";
import CertificateChainIcon from "icons/CertificateIntermediateIcon";
import MyCertificateIcon from "icons/MyCertificateIcon";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CertAccordionDetails from "components/Cert/CertAccordionDetails";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import RotateOfflineRootCertDialog from "views/Settings/rotateCert/rotateOfflineRootCertDialog/RotateOfflineRootCertDialog";
import useInstanceCSR from "services/backend/useInstanceCSR";
import { saveAs } from "file-saver";
import RotateCertIcon from "icons/RotateCertIcon";
import ChainLinkIcon from "icons/ChainLinkIcon";

import Fab from "components/CustomButtons/Fab";
import RotateSelfSignedOnlineRootCertDialog from "views/Settings/rotateCert/RotateSelfSignedOnlineRootCertDialog";
import SendRequestToExternalCaDialog from "views/Settings/rotateCert/sendRequestToExternalCaDialog/SendRequestToExternalCaDialog";
import selfSignRotate from "services/backend/selfSignRotate";

export default function CertificateChain(props) {
  const cardClasses = useCardStyles();
  const componentClasses = useStyles();
  const classes = { ...cardClasses, ...componentClasses };
  const {
    common_name,
    myCert,
    myChainToRoot,
    root,
    ownedOfflineRoot,
    myCertIsSelfSignedRoot,
    updateStatus,
  } = props;

  const { params: apiParams } = useApiParams();
  const [rawCert, setRawCert] = useState({});
  const [
    showRotateOfflineRootCertDialog,
    setShowRotateOfflineRootCertDialog,
  ] = useState(false);
  const [
    showRotateSelfSignedOnlineRootCertDialog,
    setShowRotateSelfSignedOnlineRootCertDialog,
  ] = useState(false);
  const [
    showSendCSRToExternalCADialog,
    setShowSendCSRToExternalCADialog,
  ] = useState(false);
  const { CSR } = useInstanceCSR();

  const executeSelfSignedRotation = () => {
    selfSignRotate(apiParams);
    setShowRotateSelfSignedOnlineRootCertDialog(false);
    updateStatus();
  };

  const elapsedPercent = (parsedCert) => {
    const now = new Date();
    const validityLength =
      new Date(parsedCert.notAfter).getTime() -
      new Date(parsedCert.notBefore).getTime();
    const elapsedFromStart =
      now.getTime() - new Date(parsedCert.notBefore).getTime();
    const elapsedPercent = elapsedFromStart / validityLength;
    return Math.min(Math.round(elapsedPercent * 100), 100);
  };

  const handleExpand = async (cert) => {
    const __rawCert = { ...rawCert };
    if (!__rawCert[cert.serial]) {
      try {
        const result = await getCertPEM(apiParams, cert.serial);
        __rawCert[cert.serial] = result;
        setRawCert(__rawCert);
      } catch (error) {
        console.log("get cert Error", error);
      }
    }
  };
  const downloadCSR = (commonName) => {
    console.log("download...", commonName);
    const blob = new Blob([CSR], {
      type: "text/plain;charset=utf-8",
    });
    saveAs(blob, `CertManager-${commonName}.csr`);
  };
  const getAccordion = (
    cert,
    Icon,
    isRoot,
    isMyself,
    hasOwnedOfflineRoot,
    id
  ) => {
    const raw = rawCert[cert.serial];
    const elapsedPercentage = elapsedPercent(cert);
    return (
      <Accordion
        key={id}
        TransitionProps={{ unmountOnExit: true }}
        onChange={(event, expanded) => (expanded ? handleExpand(cert) : null)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={
            isRoot
              ? ownedOfflineRoot
                ? classes.ownOfflineRoot
                : classes.externalRoot
              : isMyself
              ? myCertIsSelfSignedRoot
                ? classes.selfSignedRoot
                : classes.myselfNotRoot
              : classes.chain
          }
        >
          <div className={classes.watermark}>
            {isRoot
              ? ownedOfflineRoot
                ? "Ecosystem ROOT (offline archived)"
                : "Ecosystem ROOT"
              : isMyself
              ? myCertIsSelfSignedRoot
                ? "This CertManager = online ROOT"
                : "This CertManager"
              : "Subordinated CA"}
          </div>
          <GridContainer>
            <GridItem className={classes.gridItemToAlignCenter} xs={12}>
              <Box className={classes.greenBox}>
                <Box className={classes.blueBox}>
                  <Box className={classes.yellowBox}>
                    <CertificateIcon />
                    <Icon />
                  </Box>
                  <Tooltip
                    title={`Serial number: ${cert.serial}`}
                    className={classes.yellowBox}
                  >
                    <IconButton>
                      <Identicon
                        size="16"
                        string={cert.serial}
                        className={classes.certSerialIcon}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box className={classes.yellowBox}>
                  <CircularProgress
                    variant="determinate"
                    value={elapsedPercentage}
                    className={classes.circularProgress}
                  />
                  <Box className={classes.BoxWithElapsedProgress}>
                    <Typography
                      variant="caption"
                      component="div"
                      color="textSecondary"
                    >{`${elapsedPercentage}%`}</Typography>
                  </Box>
                </Box>
                <Box className={classes.yellowBox}>
                  {cert.issuer.commonName}{" "}
                  <ChainLinkIcon className={classes.ChainLinkIcon} />
                  <strong>{`  ${cert.subject.commonName}`}</strong>
                  {` (org: ${cert.subject.organizationName})`}
                </Box>
                {isMyself ? (
                  <>
                    {myCertIsSelfSignedRoot ? (
                      <Fab
                        className={classes.yellowBox}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowRotateSelfSignedOnlineRootCertDialog(true);
                        }}
                        size="sm"
                        variant="extended"
                        color="primary"
                      >
                        <RotateCertIcon />
                        Rotate this certificate
                      </Fab>
                    ) : (
                      <>
                        {ownedOfflineRoot ? (
                          <Fab
                            className={classes.yellowBox}
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowRotateOfflineRootCertDialog(true);
                            }}
                            size="sm"
                            variant="extended"
                            color="primary"
                          >
                            <RotateCertIcon />
                            Rotate this &amp; my offline root
                          </Fab>
                        ) : (
                          <Fab
                            className={classes.yellowBox}
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowSendCSRToExternalCADialog(true);
                            }}
                            size="sm"
                            variant="extended"
                            color="primary"
                          >
                            <RotateCertIcon />
                            Cert rotation
                          </Fab>
                        )}
                      </>
                    )}
                  </>
                ) : null}
              </Box>
            </GridItem>
            <GridItem xs={4} className={classes.gridItemToAlignCenter}>
              <Typography variant="subtitle1" className={classes.certDates}>
                valid {moment(cert.notBefore).format("D.M.YYYY")} -{" "}
                {moment(cert.notAfter).format("D.M.YYYY")} (exp:{" "}
                {moment(cert.notAfter).fromNow()})
              </Typography>
            </GridItem>
            <GridItem className={classes.gridItemToAlignCenter}>
              <Typography variant="body1" className={classes.certAttributes}>
                <strong>{cert.extensions.basicConstraints}</strong>
                <br />
                <strong>Key usage:</strong> {cert.extensions.keyUsage}
                <br />
                {cert.extensions.extendedKeyUsage}
              </Typography>
            </GridItem>
          </GridContainer>
        </AccordionSummary>
        {raw ? (
          <CertAccordionDetails
            pem={raw.pem}
            parsed={raw.parsed}
            serial={cert.serial}
          />
        ) : null}
      </Accordion>
    );
  };
  return (
    <>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>
            <CertificateIcon /> My certificate
          </h4>
          {!myCertIsSelfSignedRoot ? (
            <p className={classes.cardCategoryWhite}>
              and my trust chain to the Ecosystem ROOT
            </p>
          ) : null}
        </CardHeader>
        <CardBody profile>
          {!myCertIsSelfSignedRoot ? (
            <>
              {getAccordion(root, CertificateRootIcon, true, false, false)}
              {myChainToRoot
                ? myChainToRoot.map((val, index, array) => {
                    const cert = array[array.length - 1 - index];
                    return getAccordion(
                      cert,
                      CertificateChainIcon,
                      false,
                      false,
                      false,
                      index
                    );
                  })
                : null}
            </>
          ) : null}
          {getAccordion(myCert, MyCertificateIcon, false, true, false)}
        </CardBody>
      </Card>
      <RotateOfflineRootCertDialog
        open={showRotateOfflineRootCertDialog}
        onClose={() => setShowRotateOfflineRootCertDialog(false)}
        common_name={common_name}
        downloadCSR={downloadCSR}
        updateStatus={updateStatus}
      />
      <RotateSelfSignedOnlineRootCertDialog
        open={showRotateSelfSignedOnlineRootCertDialog}
        onClose={() => setShowRotateSelfSignedOnlineRootCertDialog(false)}
        onTriggerRotate={() => executeSelfSignedRotation()}
      />
      <SendRequestToExternalCaDialog
        open={showSendCSRToExternalCADialog}
        onClose={() => setShowSendCSRToExternalCADialog(false)}
        updateStatus={updateStatus}
      />
    </>
  );
}

CertificateChain.propTypes = {
  common_name: PropTypes.string,
  myChainToRoot: PropTypes.array,
  myCert: PropTypes.object,
  root: PropTypes.object,
  ownedOfflineRoot: PropTypes.bool,
  myCertIsSelfSignedRoot: PropTypes.bool,
  updateStatus: PropTypes.func,
};
