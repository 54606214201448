import React, { useState, useEffect } from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import AppUserIcon from "@material-ui/icons/AssignmentInd";
import useAppCerts from "services/backend/useAppCerts";
import CertificateTable from "./components/CertificateTable";
import useStyles from "./styles";
import VideoDialog from "./components/VideoDialog";
import userCertSequenceImage from "assets/img/user-certification-sequence.png";
import Fab from "components/CustomButtons/Fab";

export default function Users() {
  const columns = [
    { title: "User", field: "commonName" },
    { title: "Certs", field: "certCount" },
    { title: "Last cert expires", field: "expire" },
  ];

  const classes = useStyles();
  const { certs: appCerts, update: updateAppCerts } = useAppCerts();
  const [certs, setCerts] = useState({});
  const [videoDialogOpen, setVideoDialogOpen] = useState(false);

  useEffect(() => {
    const subjectCerts = {};
    appCerts.forEach((cert) => {
      let currentCert = subjectCerts[cert.subject_common_name];
      if (!currentCert) {
        // no such cert found, initiate an element
        currentCert = { inAllCerts: {}, certs: [], extra: {} };
        currentCert.inAllCerts.totalCount = 0;
        currentCert.inAllCerts.validCount = 0;
      }
      currentCert.inAllCerts.commonName = cert.subject_common_name;
      currentCert.inAllCerts.totalCount++;
      if (!cert.expired && cert.status == "V") {
        currentCert.inAllCerts.validCount++;
      }
      if (
        !currentCert.inAllCerts.maxExpire ||
        currentCert.inAllCerts.maxExpire < cert.not_after
      ) {
        currentCert.inAllCerts.maxExpire = cert.not_after;
      }
      if (cert.not_before)
        if (
          !currentCert.inAllCerts.mostRecent ||
          currentCert.inAllCerts.mostRecent < cert.not_before
        ) {
          currentCert.inAllCerts.mostRecent = cert.not_before;
          var input = cert.extensions["1.2.246.10.23378962.10.10"];
          if (input) {
            var avatarURL = input.substring(input.search("https://"));
            currentCert.extra.avatar = avatarURL;
          }
        }
      currentCert.certs.push(cert);
      subjectCerts[cert.subject_common_name] = currentCert;
    });
    setCerts(subjectCerts);
  }, [appCerts]);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Fab
          variant="extended"
          color="primary"
          onClick={() => setVideoDialogOpen(true)}
        >
          ?
        </Fab>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>
              <AppUserIcon />
              Certified Users
            </h4>
            <p className={classes.cardCategoryWhite}>
              An app user will automatically receive certificate after successul
              Auth0 authention. You have the power to revoke the certificates.
            </p>
          </CardHeader>
          <CardBody>
            <VideoDialog
              title="User certification process (example)"
              url="https://youtu.be/gvTbcXihjEo"
              image={userCertSequenceImage}
              imageAlt="Certification sequence"
              open={videoDialogOpen}
              onClose={() => setVideoDialogOpen(false)}
            />

            <CertificateTable
              certs={certs}
              columns={columns}
              update={updateAppCerts}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
