import React from "react";
// react plugin for creating charts
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import Business from "@material-ui/icons/Business";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import BookIcon from "@material-ui/icons/MenuBookOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMoreOutlined";
import CertificateIcon from "icons/CertificateIcon";

import Success from "components/Typography/Success";
import DeviceIcon from "icons/DeviceIcon";
import VerifySuccessIcon from "icons/VerifySuccessIcon";
import AuthenticatedUserIcon from "icons/AuthenticatedUserIcon";
import EcosystemImage from "assets/img/ecosystems.svg";
import Divider from "@material-ui/core/Divider";
import {
  successColor,
  whiteColor,
  grayColor,
  hexToRgb,
} from "assets/jss/material-dashboard-react.js";
import useCertSubjectCount from "services/backend/useCertSubjectCount";

const styles = {
  ecosystemImage: {
    margin: "20px",
  },
  successText: {
    color: successColor[0],
  },
  upArrowCardCategory: {
    width: "16px",
    height: "16px",
  },
  stats: {
    color: grayColor[0],
    display: "inline-flex",
    fontSize: "12px",
    lineHeight: "22px",
    "& svg": {
      top: "4px",
      width: "16px",
      height: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      top: "4px",
      fontSize: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px",
    },
  },
  cardCategory: {
    color: grayColor[0],
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    paddingTop: "10px",
    marginBottom: "0",
  },
  cardCategoryWhite: {
    color: "rgba(" + hexToRgb(whiteColor) + ",.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitle: {
    color: grayColor[2],
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  cardTitleWhite: {
    color: whiteColor,
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const { appCount, deviceCount, subCaCount } = useCertSubjectCount();
  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <DeviceIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Devices</p>
              <h3 className={classes.cardTitle}>{deviceCount}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <CertificateIcon />
                Cert count, incl. expired
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <AssignmentInd />
              </CardIcon>
              <p className={classes.cardCategory}>Users</p>
              <h3 className={classes.cardTitle}>{appCount}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <CertificateIcon />
                Cert count, incl. expired
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Business />
              </CardIcon>
              <p className={classes.cardCategory}>Partners</p>
              <h3 className={classes.cardTitle}>{subCaCount}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <CertificateIcon />
                Cert count, incl. expired
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <BookIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Welcome to</p>
              <h3 className={classes.cardTitle}>
                Cert Manager <small>by ControlThings</small>
              </h3>
            </CardHeader>
            <CardBody>
              This Cert Manager provides <b>certificate-based authentication</b>{" "}
              for a landscape of app users, service technicians, partner
              companies, remote support services and IoT devices. This has been
              designed with two ecosystems in mind - the manufacturer ecosystem
              which always is present, and optionally some sort of customer
              ecosystem.
              {/**  This Cert Manager has been designed to offer intuitive and secure access management for both <ol><li>device manufacturer ecosystems</li><li>end-user ecosystems</li></ol>*/}
              <div className={classes.ecosystemImage}>
                <img src={EcosystemImage} alt="Ecosystem image" />
              </div>
              <h6>Manufacturer ecosystem</h6>
              This Cert Manager makes it easy to setup a manufacturer private
              ecosystem, with full control over whom to trust. This allows the
              manufacturer to control 1) certified equipment, 2) own
              organisation (certified staff), 3) certified service and support
              provided via partners. This type of private ecosystem is achieved
              by using PKI with self-signed root certificates (or certificate
              pinning of the ecosystem root certificate). The pinned
              manufacturer ecosystem root certificate is installed in all the
              participating devices and apps, and it enables manufacturers to
              <ul>
                <li>
                  Remotely authenticate own products, distinguish them from
                  clone-copies or spoofed devices
                </li>
                <li>
                  Delegate service jobs to local service providers and
                  authenticate those partners. The partners are managing their
                  own subordinated organisations
                </li>
                <li>
                  Authenticate equipment, tools and spare parts - making sure
                  that maintenance is provided with approved high quality tools
                </li>
                <li>
                  Enable own products to authenticate the manufacturer online
                  services, remote support / cloud services - preventing service
                  spoofing
                </li>
                <li>
                  Authenticate users, making sure that manufacturer channels
                  only are used by manufacturer staff, such as certified service
                  technicians
                </li>
              </ul>
              The manufacturer ecosystem is essential for security, and for
              controlling delegated services.
              <h6>Device Customer Ecosystem</h6>
              The customer ecosystem is very different depending on device
              application:
              <ol>
                <li>
                  Industrial devices, such as a paper machine or a harbour
                  crane. The customer ecosystem is managed paper mill company or
                  the harbour
                </li>
                <li>
                  Building devices, such as an escalator. The customer ecosystem
                  is controlled by facility managers
                </li>
                <li>Consumer devices, such as an home appliance.</li>
              </ol>
              In certain applications the customer is not supposed to connect to
              the device at all, and the manufacturer ecosystem is sufficient on
              it's own. In other cases, the customer ecosystem if defined by the
              device administrator who commission the device, and who has no
              control over the manufacturer ecosystem.
              <br />
              The access management for the customer ecosystem can be tailored
              to fit the application, for industrial applications enterprise IAM
              integration is the recommended default.
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <h5>
                    <VerifySuccessIcon /> Verifiability{" "}
                    <small>
                      {" "}
                      - Our main focus is to expose who has caused what
                    </small>
                  </h5>
                </AccordionSummary>
                <AccordionDetails>
                  <p>
                    We provide tamper protected on-site logging on IoT devices
                    and in apps, in untrusted environments. The verifiable
                    evidences are based on digital signatures, produced by the
                    counterparties identities who have triggered sensitive
                    events. The identities are identified by their public keys,
                    which can be translated to people, owners and organisations
                    using certificates.
                  </p>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <h5>
                    <CertificateIcon /> Certificates provides higher security{" "}
                    <small>
                      {" "}
                      - Features that token-based security cannot match
                    </small>
                  </h5>
                </AccordionSummary>
                <AccordionDetails>
                  Certificates provides superior security compared to tokens
                  because
                  <ol>
                    <li>
                      Certificates are issued to digital identities, whose
                      private key can be steal-protected by hardware (HSM),
                      making it practically impossible to copy them. Thereby you
                      can stay sure that you do not interact with a malicious
                      clone-copy.
                    </li>
                    <li>
                      The authentication is achieved with no need to send
                      sensitive secrets to the counterparty. It's enough that
                      certificate holder proves the corresponding possessing the
                      private key using a digital signature. Tokens on the other
                      hand, are secrets (privileges) that are sent over all the
                      time (relying on carefully secured channels) - and they
                      provide no means to distinguish malicous parties from
                      expected parties.
                    </li>
                    <li>
                      In opposite to token-based security where privileged
                      access is provided to anyone who have obtained a token
                      (scoped according to the permissions attribute), in
                      certificate based security you{" "}
                      <b>can actually verify who</b> the counterparty is.
                    </li>
                  </ol>
                </AccordionDetails>
              </Accordion>
              {/** 
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <h5>
                    <AuthenticatedUserIcon /> Use Cases{" "}
                    <small>
                      {" "}
                      - Mutual peer-to-peer authentication (even without
                      Internet access)
                    </small>
                  </h5>
                </AccordionSummary>
                <AccordionDetails>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <h5>Remote Support Center connection to the device</h5>
                      <h6>The Remote Support Center Side</h6>
                      <p>
                        <b>Threat: </b> Plagiarism / Clone copies of device;
                        Non-eliglible use of support / warranty
                        <br />
                        <b>What to determine: </b> Is it a genuine device?{" "}
                        <br />
                        When making tools and support available for a product
                        (device), it's an interest of the manufacturer to avoid
                        plagiarism as free riders. It would be a pity if a
                        foreign manufacturer would produce exact copies of your
                        hardware, and take benefit of the software and support
                        that your providing.
                        <br />
                        <br />
                        <b>Solution:</b> Reliable device authentication
                        <br />
                        This Cert Manager suggests and supports a workflow,
                        where device identities are steal-protected using HSM
                        hardware. The chip manufacturer installs individual
                        bootstrap certificates, which need to be white listed
                        (imported) in this Cert Manager before they can register
                        and be used.
                      </p>
                      <h6>The device side</h6>
                      <b>Threat: </b> Malicious party spoofing the Remote
                      Support Center
                      <br />
                      <b>Solution:</b> Reliable authentication: verify it's a
                      participant of the Manufacturer private Ecosystem (using
                      the pinned root cert), with the required role attribute
                      included in the cert
                      <br />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Divider />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <h5>
                        Service technician is locally connected via Bluetooth to
                        device
                      </h5>
                    </GridItem>
                  </GridContainer>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <h5>Use Cases</h5>
                </AccordionSummary>
                <AccordionDetails>
                  <ol>
                    <li>Commissioning</li>
                    <li>Device registration</li>
                    <li>Bluetooth tethering</li>
                    <li>Mutual authentication</li>
                  </ol>
                  Manufacturer private cert ecosystem, Bluetooth enabled
                  devices, certified service technician using a mobile app -
                  login through OAuth, get cert, mutually authenticate the
                  device / service guy - just in time activate -{" "}
                  <b>Warranty registration</b>
                </AccordionDetails>
              </Accordion> */}
            </CardBody>
            <CardFooter stats>
              <div className={classes.stats}>
                <Success>
                  <InfoIcon />
                </Success>
                <a
                  href="https://www.controlthings.fi"
                  onClick={(e) => e.preventDefault()}
                >
                  Get in touch to learn more
                </a>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
