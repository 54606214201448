export const removeFromExtension = (str) => {
  if (!str) {
    return;
  }
  // remove non-printable ASCII chars
  const _str = str.replace(/[^\x20-\x7F]/g, "");
  // remove empty space from beginning
  if (_str.charCodeAt(0) === 32) {
    return _str.substr(1);
  }
  return _str;
};
