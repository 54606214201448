import React, { useState } from "react";
import LayoutOneCard from "components/LayoutOneCard/LayoutOneCard";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import MeetingRoomOutlined from "@material-ui/icons/MeetingRoomOutlined";
import useMyInstances from "services/backend/useMyInstances";
import useStyles from "./styles";

import Button from "components/CustomButtons/Button";
import NewInstanceDialog from "../layouts/newInstanceDialog/NewInstanceDialog";

export default function MyInstances() {
  // ref to help us initialize PerfectScrollbar on windows devices

  const [openInstanceDialog, setOpenInstanceDialog] = useState(false);
  const classes = useStyles();
  const [selectedInstance, setAge] = React.useState("");
  const { user, logout } = useAuth0();
  const { instances } = useMyInstances(false);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handelLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  return (
    <LayoutOneCard>
      <Card>
        <CardHeader className={classes.header} color="primary">
          Welcome to Cert Manager {user.nickname ? user.nickname : user.name}
          <Tooltip title="Logout">
            <IconButton
              className={classes.logoutButton}
              color="inherit"
              onClick={handelLogout}
            >
              <MeetingRoomOutlined />
            </IconButton>
          </Tooltip>
        </CardHeader>
        <CardBody>
          Select one of your Cert Manager instances from the list, or try one of
          the public demo instances.
          <Button onClick={() => setOpenInstanceDialog(true)} color="primary">
            Create a new CertManager instance
          </Button>
          <NewInstanceDialog
            open={openInstanceDialog}
            onClose={() => setOpenInstanceDialog(false)}
          />
          <div className={classes.sameRow}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">
                Available Cert Manager instances
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedInstance}
                onChange={handleChange}
              >
                {instances.map((instance, id) => (
                  <MenuItem key={id} value={instance.basepath}>
                    {instance.basepath}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Link to={selectedInstance}>
              <Button variant="contained" color="primary">
                Select
              </Button>
            </Link>
          </div>
        </CardBody>
      </Card>
    </LayoutOneCard>
  );
}
