import React from "react";
import PropTypes from "prop-types";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import TriggeringDeviceIcon from "@material-ui/icons/TouchAppOutlined";
import { makeStyles } from "@material-ui/core/styles";
import EventLogIcon from "icons/EventLogIcon";
import hacking_image from "assets/img/Computer_hacking.jpg";
import VerifySuccessIcon from "icons/VerifySuccessIcon";

import { successColor } from "assets/jss/material-dashboard-react.js";

const styles = {
  eventCard: {
    marginTop: "-3px",

    marginLeft: "20px",
    fontSize: "14px",
    display: "flex",
    border: "1.5px solid orange",
    borderRadius: "6px",
    backgroundImage: `url(${VerifySuccessIcon})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: "rgba(255, 255, 255, 35%)",
  },
  eventVerifiedOverlay: {
    zIndex: "3 !important",
    marginTop: "-17px",
    marginBottom: "-5px",
    color: successColor[0],
    verticalAlign: "middle",
    //fontSize: "60%",
  },
  eventVerifiedIcon: {
    zIndex: "3 !important",
    marginBottom: "-5px",

    marginLeft: "40px",
    color: successColor[0],
    //transform: "rotate(20deg)",
  },
  eventId: {
    transform: "rotate(-90deg)",
    fontSize: "60%",
    marginLeft: "-11px",
    zIndex: "3 !important",
  },
  linkToRawData: {
    fontSize: "10px",
  },
  triggerChip: {
    fontSize: "75%",
  },
  loggerChip: {
    fontSize: "75%",
  },
  participants: {
    flexBasis: "20%",
    display: "inline",
  },
  verifiedAnchor: {
    transform: "rotate(90deg)",
    marginTop: "-5px",
    marginLeft: "60px",
    color: "orange",
  },
  eventData: {
    flexBasis: "70%",
    display: "inline",
    margin: "20 px",
    fontSize: "75%",
    border: "1px dashed lightgray",
  },
};

const useStyles = makeStyles(styles);

export default function EventEntry(props) {
  const classes = useStyles();
  const {
    eventId,
    triggerCert,
    dataIngress,
    dataDigest,
    dataURL,
    previousDigest,
    img: image,
  } = props;
  return (
    <>
      <Tooltip
        title={`Event ${eventId} signatures spans over the hash of ${previousDigest}`}
      >
        <IconButton size="small">
          <EventLogIcon className={classes.verifiedAnchor} />
        </IconButton>
      </Tooltip>
      <Paper elevation={4} className={classes.eventCard}>
        <div className={classes.eventId}>{eventId}</div>
        <div className={classes.participants}>
          {/**
            <Chip className={classes.loggerChip}
                size="small"
                avatar={<LoggerIcon />}
                label={`log: ${loggerCert}`}
                
            /> */}
          <Tooltip title={`Event was triggered by ${triggerCert}`}>
            <Chip
              className={classes.triggerChip}
              size="small"
              avatar={<TriggeringDeviceIcon />}
              label={`${triggerCert}`}
            />
          </Tooltip>
        </div>
        <div className={classes.eventData}>
          {dataIngress}{" "}
          {image ? (
            <img
              src={hacking_image}
              alt={"Computer_hacking.jpg"}
              width="200px"
            />
          ) : null}
          <div className={classes.eventVerifiedOverlay}>
            <IconButton className={classes.eventVerifiedIcon}>
              <VerifySuccessIcon />
            </IconButton>{" "}
            AUTHOR, EVENT DATA AND EVENT ANCHORING VERIFIED
          </div>
        </div>
        <div className={classes.linkToRawData}>
          <a href={dataURL}>Raw data</a>
        </div>
        <div>{dataDigest}</div>
      </Paper>
    </>
  );
}

EventEntry.propTypes = {
  eventId: PropTypes.string,
  triggerCert: PropTypes.string,
  dataIngress: PropTypes.string,
  dataDigest: PropTypes.string,
  dataURL: PropTypes.string,
  previousDigest: PropTypes.string,
  img: PropTypes.bool,
};
