const prod = {
  showDemoOnly: true,
  url: {
    FRONTEND: `https://certmanager.controlthings.fi`,
    BACKEND: `https://ca.controlthings.fi`,
  },
};

const dev = {
  showDemoOnly: false,
  url: {
    FRONTEND: `http://localhost:3000`,
    BACKEND: `http://localhost:8080`,
  },
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;
