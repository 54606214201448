import React, { useEffect, useState, useContext } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { CMContext } from "services/backend/CMContext";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import { states } from "routes";
// core components
import NavbarLinks from "components/Navbars/NavbarLinks.js";

import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const classes = useStyles();
  // verifies if routeName is the one active (in browser input)

  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }
  const { color, logo, image, logoText, routes, url } = props;

  const global = useContext(CMContext);
  const { instance } = global.instance;

  const [status, setStatus] = useState();
  useEffect(() => {
    if (instance.status) {
      setStatus(instance.status);
    }
  }, [instance]);

  var links = (
    <List className={classes.list}>
      {routes.map((route, key) => {
        if (
          process.env.NODE_ENV === "production" &&
          route.path === "/event-log"
        ) {
          return;
        }
        var activePro = " ";
        var listItemClasses;
        const path = url + route.path;
        if (route.path === `/app-release.apk`) {
          activePro = classes.activePro + " ";
          listItemClasses = classNames({
            [" " + classes[color]]: false,
          });
          return (
            <a
              key={key}
              href={route.path}
              download
              className={activePro + classes.item}
            >
              <ListItem button className={classes.itemLink + listItemClasses}>
                {typeof route.icon === "string" ? (
                  <Icon
                    className={classNames(classes.itemIcon, whiteFontClasses)}
                  >
                    {route.icon}
                  </Icon>
                ) : (
                  <route.icon
                    className={classNames(classes.itemIcon, whiteFontClasses)}
                  />
                )}
                <ListItemText
                  primary={route.name}
                  className={classNames(classes.itemText, whiteFontClasses)}
                  disableTypography={true}
                />
              </ListItem>
            </a>
          );
        } else {
          listItemClasses = classNames({
            [" " + classes[color]]: activeRoute(path),
          });
        }
        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(path),
        });
        return (
          <NavLink
            to={path}
            onClick={(e) =>
              status === route.state || route.state === states.ALL
                ? null
                : e.preventDefault()
            }
            className={activePro + classes.item}
            activeClassName="active"
            key={key}
          >
            <ListItem
              button
              disabled={status !== route.state && route.state !== states.ALL}
              className={classes.itemLink + listItemClasses}
            >
              {typeof route.icon === "string" ? (
                <Icon
                  className={classNames(classes.itemIcon, whiteFontClasses)}
                >
                  {route.icon}
                </Icon>
              ) : (
                <route.icon
                  className={classNames(classes.itemIcon, whiteFontClasses)}
                />
              )}
              <ListItemText
                primary={route.name}
                className={classNames(classes.itemText, whiteFontClasses)}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        );
      })}
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <a href="/" className={classNames(classes.logoLink)} target="_blank">
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
        {logoText}
      </a>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            <NavbarLinks />
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={"left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  url: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
  color: PropTypes.string,
};
