import { makeStyles } from "@material-ui/styles";

export default makeStyles(() => ({
  inputDiv: {
    width: 320,
    display: "flex",
    flexWrap: "wrap",
    margin: "0px",
  },
}));
