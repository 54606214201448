import { useState, useEffect, useCallback } from "react";
import getApi from "./api";

export default function useInstances(demoOnly, activeOnly) {
  const [instances, setInstances] = useState([]);
  const [error, setError] = useState(false);

  const update = useCallback(() => {
    getInstances(demoOnly, activeOnly)
      .then((res) => {
        setInstances(res);
      })
      .catch((err) => {
        setError(err);
      });
  }, []);

  useEffect(() => {
    update();
  }, [update]);

  return { instances, update, error };
}

export function getInstances(demoOnly, activeOnly) {
  const api = getApi();
  return new Promise((resolve, reject) => {
    api
      .post("/getInstances", { demoOnly, activeOnly })
      .then((res) => {
        if (res.error) {
          reject(res.error);
        }
        if (res.data.error) {
          reject(res.data.error);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
