import React, { useState } from "react";
import PropTypes from "prop-types";

import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";
import useClientSecret from "services/backend/useClientSecret";

//Icons
import IconButton from "@material-ui/core/IconButton";
import FilterNone from "@material-ui/icons/FilterNone";
import DevTutorialDialog from "../../../../components/Dialog/DevTutorialDialog";
import Button from "components/CustomButtons/Button.js";

export default function M2M(props) {
  const { basepath, auth0domain } = props;

  const [openDevTutorialDialog, setOpenDevTutorialDialog] = useState(false);

  const { id, secret } = useClientSecret();

  return (
    <>
      <TextField
        style={{ margin: 10 }}
        label="Client Id"
        value={id}
        disabled={true}
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title="Copy to clipboard">
                <IconButton
                  onClick={() => navigator.clipboard.writeText(id)}
                  edge="end"
                >
                  <FilterNone />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
      <br />
      <TextField
        style={{ margin: 10 }}
        label="Client secret"
        value={secret}
        disabled={true}
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title="Copy to clipboard">
                <IconButton
                  onClick={() => navigator.clipboard.writeText(secret)}
                  edge="end"
                >
                  <FilterNone />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
      <br />
      <Button
        style={{ margin: 10 }}
        color="primary"
        onClick={() => setOpenDevTutorialDialog(true)}
      >
        Device Developer Tutorial
      </Button>
      <DevTutorialDialog
        open={openDevTutorialDialog}
        onClose={() => setOpenDevTutorialDialog(false)}
        clientId={id}
        clientSecret={secret}
        basepath={basepath}
        auth0domain={auth0domain}
        fullWidth={true}
        maxWidth={false}
      />
    </>
  );
}

M2M.propTypes = {
  basepath: PropTypes.string,
  auth0domain: PropTypes.string,
};
