import { useState, useEffect, useCallback } from "react";
import getApi from "./api";
import useApiParams from "services/backend/useApiParams";

export default function useClientSecret() {
  const [secret, setSecret] = useState("");
  const [id, setId] = useState("");
  const [error, setError] = useState(false);
  const { params } = useApiParams();

  const update = useCallback(() => {
    if (params) {
      getAppCerts(params)
        .then((res) => {
          setSecret(res.client_secret);
          setId(res.client_id);
        })
        .catch((err) => {
          setError(err);
        });
    }
  }, [params]);

  useEffect(() => {
    update();
  }, [update, params]);

  return { secret, id, update, error };
}

export function getAppCerts(apiParams) {
  const api = getApi(apiParams);
  return new Promise((resolve, reject) => {
    api
      .get("/getClientSecret")
      .then((res) => {
        if (res.error) {
          reject(res.error);
        }
        if (res.data.error) {
          reject(res.data.error);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
