import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import useStyles from "./styles";

import useCAConfig from "services/backend/useCAConfig";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Button from "components/CustomButtons/Button.js";

export default function EditConfDialog(props) {
  const classes = useStyles();

  const { onClose, open } = props;

  const [opensslConf, setOpensslConf] = useState();

  const { config, save, error } = useCAConfig();

  useEffect(() => {
    if (error) {
      console.log("SaveError", error);
    }
  }, [error]);

  const handleConfSave = () => {
    onClose();
    save(opensslConf);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      fullWidth={true}
      aria-labelledby="form-confdialog-title"
    >
      <DialogTitle id="form-dialog-title">OpenSSL Conf editor</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Manage the openssl conf file from here
        </DialogContentText>
        <TextareaAutosize
          className={classes.confTextarea}
          onChange={(event) => setOpensslConf(event.target.value)}
          aria-label="confFileArea"
          rowsMin={50}
          placeholder="openssl conf"
          defaultValue={config}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

EditConfDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
