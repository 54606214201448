import React from "react";
import PropTypes from "prop-types";
import useStyles from "./styles";
import useCardStyles from "assets/jss/cert-manager/components/cardStyle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import js from "react-syntax-highlighter/dist/esm/languages/hljs/javascript";
import bash from "react-syntax-highlighter/dist/esm/languages/hljs/bash";
import docco from "react-syntax-highlighter/dist/esm/styles/hljs/docco";
import Typography from "@material-ui/core/Typography";
import { config } from "Constants";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import Button from "components/CustomButtons/Button.js";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";

SyntaxHighlighter.registerLanguage("javascript", js);
SyntaxHighlighter.registerLanguage("bash", bash);

export default function DevTutorialDialog(props) {
  const cardClasses = useCardStyles();
  const componentClasses = useStyles();
  const classes = { ...cardClasses, ...componentClasses };
  const [selectedSSL, setSelectedSSL] = React.useState(0);

  const handleChangeSelectedSSL = (event, selectedSSLValue) => {
    setSelectedSSL(selectedSSLValue);
  };

  const {
    onClose,
    open,
    clientId,
    clientSecret,
    basepath,
    auth0domain,
    children,
    value,
    index,
    ...rest
  } = props;
  const opensslGenerateKeyAndCSR = `# On some platforms, we need to first generate a random file for openssl
# openssl rand -writerand ~/.rnd

mkdir server client
openssl ecparam -name prime256v1 -out server/my-priv.key -genkey
openssl req -new -key server/my-priv.key -out server/my-request.csr -subj "/CN=my-test-server"
openssl ecparam -name prime256v1 -out client/my-priv.key -genkey
openssl req -new -key client/my-priv.key -out client/my-request.csr -subj "/CN=my-test-client"`;

  const codeObtainAuth0Token = `const axios = require("axios");
const util = require("util");
const fs = require("fs");
const write = util.promisify(fs.writeFile);

const url = "https://${auth0domain}/oauth/token";
const headers = { "content-type": "application/json" };
const json = JSON.stringify({
  client_id: "${clientId}",
  client_secret: "${clientSecret}",
  audience: "https://certmanager.controlthings.fi",
  grant_type: "client_credentials",
});
const tokenFile = "access_token.txt";
  
(async () => {
  try { 
    const response = await axios.post(url, json, { headers });
    console.log(\`Token written to \${tokenFile}\`);
    await write(tokenFile, response.data.access_token, "utf8");
  } catch (error) {
    console.log(error);
  }
})();`;

  const getBootstrapCertScript = `const axios = require("axios");
const fs = require("fs");
const util = require("util");
const read = util.promisify(fs.readFile);
const write = util.promisify(fs.writeFile);
  
const url = "${config.url.BACKEND}${basepath}/device/issueBootstrapCert";
const tokenFile = "access_token.txt";
const manufacturer = "My-manufacturer";
const model = "My-model";
const serialNumber = '12345' + Math.random() * 100;
const csrFile = "my-request.csr";
const receivedCertFile = "myCert.pem";
const chainToRootFile = "chainBundle.pem";
const rootCertFile = "rootCert.pem";
const customAttr = {
  'device_id': serialNumber,
  'device_manufacturer': manufacturer,
  'device_model': model
};
  
  
(async () => {
  try {
    const csr = await read(\`\${csrFile}\`, "utf8");
    const token = await read(\`\${tokenFile}\`, "utf8");
    const attributes = JSON.stringify({csr, customAttr});
    const headers = {
      "authorization": \`Bearer \${token}\`,
      "Content-Type": "application/json"
    };
    const {issuedCert, chainToRoot, root} = (await axios.post(url, attributes, { headers })).data;

    await write(receivedCertFile, issuedCert, "utf8");
    console.log("Bootstrap certificate saved in:", receivedCertFile);
    if( chainToRoot ){
      await write(chainToRootFile, chainToRoot, "utf8");
      console.log("Intermediate CA certs (between Bootstrap and Root) are saved in:", chainToRootFile);
    }
    await write(rootCertFile, root, "utf8");
    console.log("Root certificate saved in:", rootCertFile);
  } catch (error) {
    console.log(error);
  }
})();`;
  const activateDeviceScript = `const axios = require("axios");
const fs = require("fs");
const util = require("util");
const read = util.promisify(fs.readFile);
const write = util.promisify(fs.writeFile);
  
const url = "${config.url.BACKEND}${basepath}/public/activateDevice";
const headers = {"Content-Type": "application/json"};
const csrFile = "my-request.csr";
const certFile = "myCert.pem";
const chainToRootFile = "chainBundle.pem";
const rootCertFile = "rootCert.pem";
  
(async () => {
  try {
    const csr = await read(\`\${csrFile}\`, "utf8");
    const crt = await read(\`\${certFile}\`, "utf8");
    // TODO: Add optional attributes for device activation: 
    // distributor, customer, purchaseDate 
    // TODO: fetch the configured extension variables!
    const distributor = "my-test-dealer" 
    const customer = "my-test-customer"
    const attributes = JSON.stringify({csr, crt, distributor, customer});
    const {issuedCert, chainToRoot, root} = (await axios.post(url, attributes, { headers })).data;

    await write(certFile, issuedCert, "utf8");
    console.log("Device activation certificate saved in:", certFile);
    if( chainToRoot ){
      await write(chainToRootFile, chainToRoot, "utf8");
      console.log("Intermediate CA certs (between Bootstrap and Root) are saved in:", chainToRootFile);
    }
    await write(rootCertFile, root, "utf8");
    console.log("Root certificate saved in:", rootCertFile);
  } catch (error) {
    console.log(error);
  }
})();`;

  const rotateDeviceCertScript = `const axios = require("axios");
const fs = require("fs");
const util = require("util");
const read = util.promisify(fs.readFile);
const write = util.promisify(fs.writeFile);
  
const url = "${config.url.BACKEND}${basepath}/public/rotateCert";
const headers = {"Content-Type": "application/json"};
const csrFile = "my-request.csr";
const certFile = "myCert.pem";
const chainToRootFile = "chainBundle.pem";
const rootCertFile = "rootCert.pem";
  
(async () => {
  try {
    const csr = await read(\`\${csrFile}\`, "utf8");
    const crt = await read(\`\${certFile}\`, "utf8");

    const attributes = JSON.stringify({csr, crt});
    const {issuedCert, chainToRoot, root} = (await axios.post(url, attributes, { headers })).data;

    await write(certFile, issuedCert, "utf8");
    console.log("Rotated device certificate saved in:", certFile);
    if( chainToRoot ){
      await write(chainToRootFile, chainToRoot, "utf8");
      console.log("Intermediate CA certs (between Bootstrap and Root) are saved in:", chainToRootFile);
    }
    await write(rootCertFile, root, "utf8");
    console.log("Root certificate saved in:", rootCertFile);
  } catch (error) {
    console.log(error);
  }
})();`;
  const executeBootstrapCodeForClient = `npm install axios
# install the bootstrap cert for the client
cd client
node ../get_token.js
node ../get_bootstrap_cert.js
cd ..`;

  const executeBootstrapCodeForServer = `# install the bootstrap cert for the server
cd server
node ../get_token.js
node ../get_bootstrap_cert.js
cd ..`;

  const crlDownloadNote1 = `# Download the CRL of the CA which issued the peer certificate.`;
  const crlDownloadNote2 = `# Note: If peer's certificate chain has intermediate CAs, then you must build the CRL file manually be appending to crl.pem the CRLs from each CA in the chain, with the bottommost CA's CRL first, and root CA's CRL last.`;

  const s_server_libressl = `cd server
curl ${config.url.BACKEND}${basepath}/public/CRL > crl.pem ${crlDownloadNote1}
${crlDownloadNote2}
openssl s_server  \
  -cipher ECDHE-ECDSA-AES128-GCM-SHA256  \
  -accept 8443  \
  -key my-priv.key \
  -cert myCert.pem \
  -CAfile  <(cat chainBundle.pem rootCert.pem crl.pem)   \
  -check_ss_sig   \
  -issuer_checks  \
  -crl_check    \
  -Verify 10   \
  -verify_return_error`;

  const s_server_openssl = `cd server
curl ${config.url.BACKEND}${basepath}/public/CRL > crl.pem ${crlDownloadNote1}
${crlDownloadNote2}
openssl s_server \
  -cipher ECDHE-ECDSA-AES128-GCM-SHA256 \
  -accept 8443 \
  -key my-priv.key \
  -cert myCert.pem \
  -chainCAfile chainBundle.pem \
  -CAfile rootCert.pem \
  -check_ss_sig \
  -issuer_checks \
  -CRL crl.pem \
  -crl_check \
  -Verify 10 \
  -verify_return_error`;

  const s_client_libressl = `cd client
curl ${config.url.BACKEND}${basepath}/public/CRL > crl.pem ${crlDownloadNote1}
${crlDownloadNote2}
openssl s_client \
  -connect localhost:8443 \
  -cert <(cat myCert.pem ) \
  -CAfile <(cat chainBundle.pem rootCert.pem crl.pem) \
  -crl_check -check_ss_sig  \
  -key my-priv.key  \
  -verify 10 \
  -verify_return_error`;

  const s_client_openssl = `cd client
curl ${config.url.BACKEND}${basepath}/public/CRL > crl.pem ${crlDownloadNote1}
${crlDownloadNote2}
openssl s_client \
-connect localhost:8443 \
-cert myCert.pem \
-chainCAfile chainBundle.pem \
-CAfile rootCert.pem \
-check_ss_sig \
-issuer_checks \
-CRL crl.pem \
-crl_check \
-key my-priv.key \
-verify 10 -verify_return_error`;

  const executeActivateDeviceScript = `cd client
node ../activate_device.js
cd ../server
node ../activate_device.js
cd ..`;

  const executeRotateCertScript = `cd client
node ../rotate_deviceCert.js`;

  const downloadScripts = async () => {
    const zip = JSZip();
    zip.file("createDemoServerClient.sh", opensslGenerateKeyAndCSR);
    zip.file("get_token.js", codeObtainAuth0Token);
    zip.file("get_bootstrap_cert.js", getBootstrapCertScript);
    zip.file("activate_device.js", activateDeviceScript);
    zip.file("rotate_deviceCert.js", rotateDeviceCertScript);
    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, "deviceCertificationScripts-Linux.zip");
    });
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Dialog {...rest} open={open} onClose={onClose}>
      <DialogContent>
        <Typography variant="h4">Device Developer Tutorial</Typography>
        <Typography variant="subtitle1">
          for obtaining and testing device certs
        </Typography>
        <Typography variant="h5">Prerequisites:</Typography>
        <Typography variant="body1">
          For running this tutorial, your computing environment should have the
          following tools installed:
          <ul>
            <li>OpenSSL command line tools</li>
            <li>Node.js and npm</li>
            <li>curl</li>
          </ul>
        </Typography>
        <Typography variant="caption">
          If you run the tutorial on Linux or macOS, these tools might already
          be installed on your system. If not, they can be installed via the
          package system. For node.js, we recomend &nbsp;
          <a href="https://github.com/nvm-sh/nvm#installing-and-updating">
            nvm.
          </a>
          On Windows 10, OpenSSL can be installed from&nbsp;
          <a href="https://slproweb.com/products/Win32OpenSSL.html">here</a>
          &nbsp;and node.js can be installed via&nbsp;
          <a href="https://github.com/coreybutler/nvm-windows#node-version-manager-nvm-for-windows">
            nvm-windows.
          </a>
          These instructions have been tested with Node.js 10.24.0, Node.js
          14.4.0, OpenSSL 1.1.1f and LibreSSL 2.8.3
        </Typography>

        <Accordion>
          <AccordionSummary>createDemoServerClient.sh</AccordionSummary>
          <AccordionDetails className={classes.codeGuide}>
            <SyntaxHighlighter
              language="bash"
              style={docco}
              wrapLongLines={true}
              showLineNumbers={true}
            >
              {opensslGenerateKeyAndCSR}
            </SyntaxHighlighter>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary>get_token.js</AccordionSummary>
          <AccordionDetails className={classes.codeGuide}>
            Code for obtaining a secret token, for proving to CertManager that
            you are in a trusted device manufacturing environment
            <SyntaxHighlighter
              language="javascript"
              style={docco}
              wrapLongLines={true}
              showLineNumbers={true}
            >
              {codeObtainAuth0Token}
            </SyntaxHighlighter>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary>get_bootstrap_cert.js</AccordionSummary>
          <AccordionDetails className={classes.codeGuide}>
            <Typography variant="h5">
              Script for fetching bootstrap cert
            </Typography>
            <Typography variant="subtitle1">
              For device provisioning during manufacturing
            </Typography>
            Issuing of certificates from the CertManager can be automated using
            the CertManager API. The API is accessed using a token issued by
            Auth0. Code for getting your new device keypair certified with a
            manufacturer certificate
            <SyntaxHighlighter
              language="javascript"
              style={docco}
              wrapLongLines={true}
              showLineNumbers={true}
            >
              {getBootstrapCertScript}
            </SyntaxHighlighter>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary>activate_device.js</AccordionSummary>
          <AccordionDetails className={classes.codeGuide}>
            Code for activating your bootstrap certified unactivated device
            <SyntaxHighlighter
              language="javascript"
              style={docco}
              wrapLongLines={true}
              showLineNumbers={true}
            >
              {activateDeviceScript}
            </SyntaxHighlighter>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary>rotate_deviceCert.js</AccordionSummary>
          <AccordionDetails className={classes.codeGuide}>
            Code for rotatating the cert of your already activated device
            <SyntaxHighlighter
              language="javascript"
              style={docco}
              wrapLongLines={true}
              showLineNumbers={true}
            >
              {rotateDeviceCertScript}
            </SyntaxHighlighter>
          </AccordionDetails>
        </Accordion>

        <Button onClick={downloadScripts} color="primary">
          Download device developer scripts
        </Button>

        <Typography variant="h5">
          Create device identities (keypairs)
        </Typography>
        <Typography variant="subtitle1">
          for acting as TLS server respective TLS client
        </Typography>
        <SyntaxHighlighter
          language="bash"
          style={docco}
          wrapLongLines={true}
          showLineNumbers={true}
        >
          sh createDemoServerClient.sh
        </SyntaxHighlighter>

        <Typography variant="h5">
          Install the bootstrap cert onto the devices
        </Typography>
        <Typography variant="subtitle1">
          The device is provisioned with the bootstrap cert during manufacturing
        </Typography>
        <Typography variant="caption">
          The bootstrap cert issuer may be external, and unknown by members of
          this private ecosystem, but the CertManager has whitelisted specific
          bootstrap certs that are eligble to activate
        </Typography>
        <Typography variant="body1">
          Install a bootstrap cert on the client...
        </Typography>
        <SyntaxHighlighter
          language="bash"
          style={docco}
          wrapLongLines={true}
          showLineNumbers={true}
        >
          {executeBootstrapCodeForClient}
        </SyntaxHighlighter>
        <Typography variant="body1">and likewise for the server.</Typography>
        <SyntaxHighlighter
          language="bash"
          style={docco}
          wrapLongLines={true}
          showLineNumbers={true}
        >
          {executeBootstrapCodeForServer}
        </SyntaxHighlighter>
        <Typography variant="body1">
          In this case both the server and client have been provisioned with
          bootstrap certificates from your CertManager. The bootstrap certs
          could also have been issued and pre-installed by a chip manufacturer.
          <strong>
            At least in that case, we need to obtain an activation cert in order
            to have all device subordinated to our own ecosystem root. This is
            required by peer-to-peer mTLS, where entity device knows the
            ecosystem Root but not the white listed delivered device certs
            installed by chip manufacturer.
          </strong>
          The activation also helps to keep track of which devices have been
          taken in use, and which are still in stock.
        </Typography>
        <Typography variant="h5">Activate the devices</Typography>
        <Typography variant="subtitle1">
          Install a new device cert for normal operation, when it is online for
          the very first time
        </Typography>
        <Typography variant="body1">
          When the device has been delivered to market, and connects to Internet
          for the first time, it shall fetch and install an activation cert.
          Device will authenticate using the pre-installed bootstrap cert, and
          obtain an activation cert. The bootstrap cert has to be known by the
          CertManager - whitelisted as bootstrap cert. This CertManager service
          does not require any token for authentication, since the bootstrap
          cert is used instead.
        </Typography>
        <SyntaxHighlighter
          language="bash"
          style={docco}
          wrapLongLines={true}
          showLineNumbers={true}
        >
          {executeActivateDeviceScript}
        </SyntaxHighlighter>
        <Typography variant="h5">Test the mutual TLS authentication</Typography>
        <Typography variant="subtitle1">
          Connect your client with your server using Openssl tools
        </Typography>
        <Typography variant="body1">
          Start an openssl s_server, which listens for incoming connections, and
          requiring the clients authenticate themselves with valid client certs.
          Before starting the server, a new Certificate Revocation List (CRL) is
          downloaded from the Certificate manager.
        </Typography>
        <AppBar>
          <Tabs
            value={selectedSSL}
            onChange={handleChangeSelectedSSL}
            aria-label="simple tabs example"
          >
            <Tab label="OpenSSL" {...a11yProps(0)} />
            <Tab label="LibreSSL" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <Tabs
          value={selectedSSL}
          onChange={handleChangeSelectedSSL}
          aria-label="simple tabs example"
        >
          <Tab label="OpenSSL" {...a11yProps(0)} />
          <Tab label="LibreSSL" {...a11yProps(1)} />
        </Tabs>

        {selectedSSL == 0 ? (
          <SyntaxHighlighter
            language="bash"
            style={docco}
            wrapLongLines={true}
            showLineNumbers={true}
          >
            {s_server_openssl}
          </SyntaxHighlighter>
        ) : null}
        {selectedSSL == 1 ? (
          <SyntaxHighlighter
            language="bash"
            style={docco}
            wrapLongLines={true}
            showLineNumbers={true}
          >
            {s_server_libressl}
          </SyntaxHighlighter>
        ) : null}
        <Typography variant="body1">
          Open a new shell, and use the openssl s_client, to connect to the
          s_server with cert you have obtained from this Cert Manager. Before
          starting the client, a new Certificate Revocation List (CRL) is
          downloaded from the Certificate manager.
        </Typography>
        {/** <AppBar position="static">*/}
        <Tabs
          value={selectedSSL}
          onChange={handleChangeSelectedSSL}
          aria-label="simple tabs example"
        >
          <Tab label="OpenSSL" {...a11yProps(0)} />
          <Tab label="LibreSSL" {...a11yProps(1)} />
        </Tabs>
        {/** </AppBar>*/}
        {selectedSSL == 0 ? (
          <SyntaxHighlighter
            language="bash"
            style={docco}
            wrapLongLines={true}
            showLineNumbers={true}
          >
            {s_client_openssl}
          </SyntaxHighlighter>
        ) : null}
        {selectedSSL == 1 ? (
          <SyntaxHighlighter
            language="bash"
            style={docco}
            wrapLongLines={true}
            showLineNumbers={true}
          >
            {s_client_libressl}
          </SyntaxHighlighter>
        ) : null}
        <Typography variant="h5">
          Verify the effect of cert revocation
        </Typography>
        <Typography variant="body1">
          You can test certificate revocation by putting the client cert on hold
          using the CertManager, to confirm that the connection will be rejected
          due to the entry in CRL. But first, you need to update the
          server&apos;s CRL file, and restart openssl s_server.
        </Typography>
        <Tabs
          value={selectedSSL}
          onChange={handleChangeSelectedSSL}
          aria-label="simple tabs example"
        >
          <Tab label="OpenSSL" {...a11yProps(0)} />
          <Tab label="LibreSSL" {...a11yProps(1)} />
        </Tabs>
        {selectedSSL == 0 ? (
          <SyntaxHighlighter
            language="bash"
            style={docco}
            wrapLongLines={true}
            showLineNumbers={true}
          >
            {s_server_openssl}
          </SyntaxHighlighter>
        ) : null}
        {selectedSSL == 1 ? (
          <SyntaxHighlighter
            language="bash"
            style={docco}
            wrapLongLines={true}
            showLineNumbers={true}
          >
            {s_server_libressl}
          </SyntaxHighlighter>
        ) : null}
        <Typography variant="h5">Rotate the device cert</Typography>
        <SyntaxHighlighter
          language="bash"
          style={docco}
          wrapLongLines={true}
          showLineNumbers={true}
        >
          {executeRotateCertScript}
        </SyntaxHighlighter>
        <Typography variant="h5">Rotate Root &amp; CertManager cert</Typography>
        <Typography variant="body1">
          On the Settings page, you find instructions and scripts for how to
          rotate the CertManager cert and the root cert. Click on the rotating
          arrows on your CertManager cert.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() =>
            window.open(
              "https://www.youtube.com/watch_popup?v=Q_Pe-XosBak",
              "newWindow",
              "width=640,height=360"
            )
          }
          color="primary"
        >
          Watch Tutorial
        </Button>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DevTutorialDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  clientId: PropTypes.string,
  clientSecret: PropTypes.string,
  basepath: PropTypes.string,
  auth0domain: PropTypes.string,
};
