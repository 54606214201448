import React from "react";

import { Auth0Provider } from "@auth0/auth0-react";
import config from "auth_config.json";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import CssBaseline from "@material-ui/core/CssBaseline";
import CookieConsent from "react-cookie-consent";
import Themes from "./themes";

import AppRouter from "layouts/AppRouter";

export default function App() {
  const palette = Themes.default.palette.primary;
  return (
    <Auth0Provider
      domain={config.domain}
      clientId={config.clientId}
      redirectUri={`${window.location.origin}/myinstances`}
      audience={config.audience}
    >
      <ThemeProvider theme={Themes.default}>
        <CookieConsent
          debug={process.env.NODE_ENV === "development"}
          buttonStyle={{
            backgroundColor: palette.main,
            color: palette.contrastText,
            borderRadius: 2,
          }}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>
        <CssBaseline />
        <AppRouter />
      </ThemeProvider>
    </Auth0Provider>
  );
}
