import { useState, useEffect, useCallback } from "react";
import getApi from "./api";
import useApiParams from "services/backend/useApiParams";
import { useAuth0 } from "@auth0/auth0-react";

export default function useFetchUser() {
  const { params } = useApiParams();
  const [id, setId] = useState();
  const [error, setError] = useState(false);

  const { getIdTokenClaims } = useAuth0();

  const update = useCallback(
    (idToken) => {
      if (params) {
        fetchUser(params, idToken)
          .then((res) => {
            setId(res);
          })
          .catch((err) => {
            console.log("ERR", JSON.stringify(err));
            setError(err);
          });
      }
    },
    [params]
  );

  useEffect(() => {
    if (params) {
      (async () => {
        try {
          const claims = await getIdTokenClaims();
          const token = claims.__raw;
          update(token);
        } catch (error) {
          setError(error);
        }
      })();
    }
  }, [update, params]);

  return { id, update, error };
}

export function fetchUser(apiParams, idToken) {
  const api = getApi(apiParams);
  return new Promise((resolve, reject) => {
    api
      .post("/fetchUser", { idToken })
      .then((res) => {
        if (res.error) {
          reject(res.error);
        }
        if (res.data.error) {
          reject(res.data.error);
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
