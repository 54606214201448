import tinycolor from "tinycolor2";

const primary = "#f07d00"; // ControlThings orange = #f01800
const secondary = "#9e9e9e"; // Secondary: gray
const warning = "#FF8200";
const success = "#009A44";
const info = "#8BD3E6";

const prio1 = primary;
const prio2 = warning;
const inMonitor = secondary;

const chartLine1 = primary;
const chartLine2 = secondary;

const incidentLightenRate = 30;
const incidentDarkenRate = 30;
const chartLine1LightenRate = 20;
const chartLine2LightenRate = 25;
const lightenRate = 7.5;
const darkenRate = 15;

const palette = {
  palette: {
    primary: {
      main: primary,
      light: tinycolor(primary).lighten(lightenRate).toHexString(),
      dark: tinycolor(primary).darken(darkenRate).toHexString(),
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary).lighten(lightenRate).toHexString(),
      dark: tinycolor(secondary).darken(darkenRate).toHexString(),
      contrastText: "#FFFFFF",
    },
    warning: {
      main: warning,
      light: tinycolor(warning).lighten(lightenRate).toHexString(),
      dark: tinycolor(warning).darken(darkenRate).toHexString(),
    },
    success: {
      main: success,
      light: tinycolor(success).lighten(lightenRate).toHexString(),
      dark: tinycolor(success).darken(darkenRate).toHexString(),
    },
    info: {
      main: info,
      light: tinycolor(info).lighten(lightenRate).toHexString(),
      dark: tinycolor(info).darken(darkenRate).toHexString(),
    },
    text: {
      primary: "#3A3A3A", // Darker grey = #3A3A3A
      secondary: "#6E6E6E", // Dark grey = #666666
      hint: "#B9B9B9", // Light grey = #CCCCCC
    },
    background: {
      default: "#F6F7FF",
      light: "#F5F5F5", // default: "#F3F5FF"
    },
    incidents: {
      prio1: {
        main: prio1,
        light: tinycolor(prio1).lighten(incidentLightenRate).toHexString(),
        dark: tinycolor(prio1).darken(incidentDarkenRate).toHexString(),
      },
      prio2: {
        main: prio2,
        light: tinycolor(prio2).lighten(incidentLightenRate).toHexString(),
        dark: tinycolor(prio2).darken(incidentDarkenRate).toHexString(),
      },
      inMonitor: {
        main: inMonitor,
        light: tinycolor(inMonitor).lighten(incidentLightenRate).toHexString(),
        dark: tinycolor(inMonitor).darken(incidentDarkenRate).toHexString(),
      },
    },
    chart: {
      line1: {
        main: chartLine1,
        light: tinycolor(chartLine1)
          .lighten(chartLine1LightenRate)
          .toHexString(),
      },
      line2: {
        main: chartLine2,
        light: tinycolor(chartLine2)
          .lighten(chartLine2LightenRate)
          .toHexString(),
      },
      range: {
        main: "black",
      },
    },
  },
  dateFormat: {
    // Format for the selected date range at the red top bar
    basic: "D.M.YYYY",
  },
  customShadows: {
    widget:
      "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetDark:
      "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetWide:
      "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    cardAlarmHighPrio:
      "0px 19px 38px 0px #E8EAFC, 0 3px 3px -2px #FF00001A, 0 15px 12px 0 #FF00001A",
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: "#4A4A4A1A",
      },
    },
    MuiMenu: {
      paper: {
        boxShadow:
          "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
      },
    },
    MuiSelect: {
      icon: {
        color: "#B9B9B9",
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "#F3F5FF !important",
          "&:focus": {
            backgroundColor: "#F3F5FF",
          },
        },
      },
      button: {
        "&:hover, &:focus": {
          backgroundColor: "#F3F5FF",
        },
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: "white",
      },
    },
    MuiTableRow: {
      root: {
        height: 56,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "1px solid rgba(224, 224, 224, .5)",
      },
      head: {
        fontSize: "0.95rem",
      },
      body: {
        fontSize: "0.95rem",
      },
    },
  },
};

export default palette;
